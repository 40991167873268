import {generalActions} from 'actions';
import {jimerService} from 'services';
import Store from 'store/configure';
import {Swaler} from 'swaler';
import {Message, sendUndercityMessage} from './helper';

/**
 * List of actions
 */
const OPEN_WIDGET = 'OPEN_WIDGET';
const CLOSE_VIEW = 'CLOSE_VIEW';
const FULLSCREEN_VIEW = 'FULLSCREEN_VIEW';
const PREVIEW_UPDATE_PROJECT = 'PREVIEW_UPDATE_PROJECT';
const OPEN_MODAL_IMAGE = 'OPEN_MODAL_IMAGE';
const OPEN_CTA_URL = 'OPEN_CTA_URL';
const JIMER_ATTRIBUTES_UPDATED = 'JIMER_ATTRIBUTES_UPDATED';
const JIMER_EXTERNAL_DATA_UPDATED = 'JIMER_EXTERNAL_DATA_UPDATED';
const FORBIDDEN_BOOSTED = 'FORBIDDEN_BOOSTED';
const REQUEST_PREVIEW_EVOLUTION = 'REQUEST_PREVIEW_EVOLUTION';
const UPDATE_PROJECT_JIMER = 'UPDATE_PROJECT_JIMER';
const EXTENSION_UPDATE_PROJECT_JIMER = 'EXTENSION_UPDATE_PROJECT_JIMER';
const OPEN_BOOKING_MODAL = 'OPEN_BOOKING_MODAL';
const SEND_EVENT = 'SEND_EVENT';
const LAUNCH_POKE = 'LAUNCH_POKE';

/**
 * Messenger Manager
 * Handle communication between Undercity and Stormwind (iframed)
 *
 * It's responsible for sending messages to the iframe and
 * receiving/handling ones coming from the iframe
 */
class MessengerClass {
  constructor() {
    this.store = null;
    this.logger = new Swaler('STORMWIND/Messenger');
    window.addEventListener('message', this.recvHandler, false);
  }
  initReduxStore = (store) => (this.store = store);

  sendOpenWidget = (opts) => {
    const message = new Message(OPEN_WIDGET, opts);

    sendUndercityMessage(message);
  };
  sendCloseView = () => {
    const message = new Message(CLOSE_VIEW);

    sendUndercityMessage(message);
  };
  sendFullscreenView = (isFullscreen) => {
    const message = new Message(FULLSCREEN_VIEW, {isFullscreen});

    sendUndercityMessage(message);
  };
  sendOpenModalImage = (imageUrl) => {
    const message = new Message(OPEN_MODAL_IMAGE, {imageUrl});

    sendUndercityMessage(message);
  };
  sendOpenCtaUrl = (ctaUrl) => {
    const message = new Message(OPEN_CTA_URL, {ctaUrl});

    sendUndercityMessage(message);
  };
  sendForbiddenBoosted = (evolutionId) => {
    const message = new Message(FORBIDDEN_BOOSTED, {evolutionId});

    sendUndercityMessage(message);
  };
  sendRequestPreviewEvolution = (evolutionId) => {
    const message = new Message(REQUEST_PREVIEW_EVOLUTION, {evolutionId});

    sendUndercityMessage(message);
  };
  sendUpdateProjectJimer = ({isExtension, ...data}) => {
    const message = new Message(
      isExtension === true
        ? EXTENSION_UPDATE_PROJECT_JIMER
        : UPDATE_PROJECT_JIMER,
      data
    );

    sendUndercityMessage(message);
  };
  sendOpenBookingModal = (link) => {
    const message = new Message(OPEN_BOOKING_MODAL, {link});

    sendUndercityMessage(message);
  };

  sendEvent = (event) => {
    const message = new Message(SEND_EVENT, {event});

    sendUndercityMessage(message);
  };

  sendLaunchPoke = ({pokeId, defaultUrl}) => {
    const message = new Message(LAUNCH_POKE, {pokeId, defaultUrl});

    sendUndercityMessage(message);
  };

  recvHandler = (message) => {
    if (message?.data?.fromJimo !== true) {
      // Added to prevent flood by a Chrome Extension
      return;
    }
    if (!message.data || !message.data.action) {
      return;
    }
    const data = message.data.data;

    switch (message.data.action) {
      case JIMER_EXTERNAL_DATA_UPDATED: {
        this.recvExternalDataUpdated(data);
        break;
      }
      case PREVIEW_UPDATE_PROJECT: {
        this.recvPreviewUpdateProject(data);
        break;
      }
      case JIMER_ATTRIBUTES_UPDATED: {
        this.recvCustomAttributesUpdated(data);
        break;
      }
      default: {
        return this.logger.debug(
          `Received unknown action ${message.data.action}`
        );
      }
    }
    this.logger.debug(`Handled action ${message.data.action}}`);
  };
  recvExternalDataUpdated = async (data) => {
    if (data == null) {
      return this.logger.debug(
        'Receiving null for external data, skipping process.'
      );
    }
    try {
      await jimerService.updateJimerExternalData(data);
    } catch (err) {
      return this.logger.error(
        'Updating jimer external data failed with error ',
        err.message
      );
    }
  };
  recvPreviewUpdateProject = (data) => {
    const store = Store().get().store;

    store.dispatch(generalActions.uptProject(data));
  };
  recvCustomAttributesUpdated = async (data) => {
    if (data == null) {
      return this.logger.debug(
        'Receiving null for custom attributes, skipping process.'
      );
    }
    try {
      await jimerService.updateJimerCustomAttributes(data);
      return this.logger.debug('Custom attributes set!');
    } catch (err) {
      return this.logger.error(
        'Updating jimer custom attributes failed with error ',
        err.message
      );
    }
  };
}

export const Messenger = new MessengerClass();
