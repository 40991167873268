import classnames from 'classnames';
import Button from 'components/Button';
import Vote from 'components/CardPush/components/Vote';
import Cta from 'components/Cta';
import Divider from 'components/Divider';
import {hasFlag} from 'helpers/bitwise';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans} from 'react-i18next';
import {F_SLOT_TOOLTIP} from 'scenes/Boosted';
import {
  F_OPTION_PROGRESS_ON_TARGET_CLICK,
  F_OPTION_TOUR_STEP_SKIPPABLE,
} from 'services/evolution';
import {
  STEP_TYPE_INTERVIEW,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_BLOCK,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import Interview from '../../steps/Interview';
import OpinionScale from '../../steps/OpinionScale';
import {AppContext} from 'App';
import {useContext} from 'react';

const Actions = ({
  boosted,
  currentStep,
  evolution,
  isLastStep,
  isLastTotalStep,
  onSurveyEnd,
  setStepIndex,
  stepIndex,
  onDismiss,
  handleSubmit,
  value,
  totalStepsLength,
  onBookSlotClick,
  widgetMode,
}) => {
  const {theme} = useContext(AppContext);

  const shouldHandleProgressOnTargetClick =
    hasFlag(F_OPTION_PROGRESS_ON_TARGET_CLICK, evolution.optionsFlags) ===
      true && hasFlag(F_SLOT_TOOLTIP, evolution.boostFlags);

  const hasNextButton = isLastStep !== true || isLastTotalStep !== true;
  const hasCtaButton = !!(
    currentStep?.ctaAction || currentStep?.ctaUrl != null
  );
  // const hasSkipButton =
  //   boosted === true &&
  //   hasFlag(F_OPTION_TOUR_STEP_SKIPPABLE, evolution.optionsFlags) === true &&
  //   isLastTotalStep !== true;
  const hasSkipButton = false;
  const hasCloseButton =
    isLastTotalStep && totalStepsLength > 1 && hasCtaButton !== true;
  const isVote = [
    STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    STEP_TYPE_TEXT_LONG,
    STEP_TYPE_NPS,
    STEP_TYPE_SLIDER,
  ].includes(currentStep?.type);
  const isOpinionScale = currentStep?.type === STEP_TYPE_OPINION_SCALE;
  const isInterview = currentStep?.type === STEP_TYPE_INTERVIEW;
  const onlyForBoosted = ![hasNextButton, hasCtaButton].includes(true);

  if (isLastStep === true && shouldHandleProgressOnTargetClick === true) {
    return <></>;
  }

  const {callToAction} = widgetMode === true ? theme ?? {} : {};
  const {
    backgroundColor,
    borderColor,
    borderRadius,
    fontColor,
    fontFamily,
    align,
  } = callToAction ?? {};

  const buttonStyle = {
    ...(backgroundColor != null
      ? {
          backgroundColor,
        }
      : {}),
    ...(borderColor != null
      ? {
          border: `1px solid ${borderColor}`,
        }
      : {}),
    ...(borderRadius != null
      ? {
          borderRadius: `${borderRadius}px`,
        }
      : {}),
    ...(fontColor != null
      ? {
          color: fontColor,
        }
      : {}),
    ...(fontFamily != null
      ? {
          fontFamily,
        }
      : {}),
    ...(align != null
      ? align === 'left'
        ? {
            margin: 'auto auto auto 0',
          }
        : align === 'center'
        ? {
            margin: 'auto',
          }
        : align === 'right'
        ? {
            margin: 'auto 0 auto auto',
          }
        : {}
      : {}),
  };

  if (isInterview) {
    return (
      <Interview
        currentStep={currentStep}
        isLastStep={isLastStep}
        setStepIndex={setStepIndex}
        boosted={boosted}
        onBookSlotClick={onBookSlotClick}
        stepIndex={stepIndex}
        widgetMode={widgetMode}
        onSurveyEnd={onSurveyEnd}
      />
    );
  }

  if (isVote) {
    return (
      <>
        <Divider dark />
        <Vote
          evolution={evolution}
          step={currentStep}
          onVote={handleSubmit}
          value={value}
          isLastTotalStep={isLastTotalStep}
          totalStepsLength={totalStepsLength}
          onDismiss={onDismiss}
        />
      </>
    );
  }

  if (isOpinionScale) {
    return <OpinionScale handleSubmit={handleSubmit} value={value} />;
  }

  return (
    <>
      {(boosted === true || onlyForBoosted !== true) &&
        currentStep?.type === STEP_TYPE_TEXT_BLOCK &&
        [hasNextButton, hasCloseButton, hasCtaButton, hasSkipButton].includes(
          true
        ) && (
          <>
            <Divider dark />
            <div
              className={classnames('text-block-actions-wrapper', {
                'has-cta': currentStep.ctaUrl != null,
                'has-next': isLastStep === false,
              })}>
              {hasSkipButton && (
                <Button
                  className="btn-skip"
                  style={{
                    backgroundColor:
                      document.documentElement.style.getPropertyValue(
                        '--boostedSecondaryColor'
                      ) || '#409af8',
                  }}
                  onClick={onDismiss}
                  primary>
                  <Trans i18nKey="common.skip" />
                </Button>
              )}
              {hasCtaButton && (
                <Cta
                  evolutionId={evolution.uid}
                  step={currentStep}
                  boosted={boosted}
                  widgetMode={widgetMode}
                  onClosePoke={onSurveyEnd}
                  onCtaClick={() => {
                    if (isLastTotalStep === true) {
                      onSurveyEnd();
                    }
                  }}
                />
              )}
              {hasNextButton && (
                <Button
                  className="btn-step-next"
                  style={{
                    backgroundColor:
                      document.documentElement.style.getPropertyValue(
                        '--boostedSecondaryColor'
                      ) || '#409af8',
                    ...buttonStyle,
                  }}
                  onClick={() => {
                    if (isLastTotalStep === true || isLastStep === true) {
                      onSurveyEnd();
                    } else {
                      setStepIndex(stepIndex + 1);
                    }
                  }}
                  primary>
                  <Trans i18nKey="common.next" />
                </Button>
              )}
              {hasCloseButton && (
                <Button
                  className="btn-close"
                  style={{
                    backgroundColor:
                      document.documentElement.style.getPropertyValue(
                        '--boostedSecondaryColor'
                      ) || '#409af8',
                  }}
                  onClick={() => onSurveyEnd()}
                  primary>
                  <Trans i18nKey="common.close" />
                </Button>
              )}
            </div>
          </>
        )}
    </>
  );
};

Actions.propTypes = {
  boosted: PropTypes.bool,
  currentStep: PropTypes.object,
  evolution: PropTypes.object,
  isLastStep: PropTypes.bool,
  isLastTotalStep: PropTypes.bool,
  onSurveyEnd: PropTypes.func,
  setStepIndex: PropTypes.func,
  stepIndex: PropTypes.number,
  onDismiss: PropTypes.func,
  handleSubmit: PropTypes.func,
  totalStepsLength: PropTypes.number,
  onBookSlotClick: PropTypes.func,
  widgetMode: PropTypes.bool,
};

Actions.defaultProps = {
  onDismiss: () => {},
  onBookSlotClick: () => {},
  widgetMode: false,
};

export default Actions;
