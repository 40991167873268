import React from 'react';

export default function IframeInvision(props) {
  const {link} = props;

  return (
    <iframe
      src={link}
      frameborder="0"
      title="iframe_preview_preview"
      className="preview-iframe-invision"></iframe>
  );
}
