import React from 'react';
import './_Styles.scss';
import {PopupModal} from 'react-calendly';
import {Modal} from 'components/Modal';

const BookingModal = ({isOpen, link, onRequestClose}) => {
  const isCalendly = link.includes('calendly.com');
  const isGoogle = link.includes('google.com');

  if (isOpen !== true) {
    return <></>;
  }
  if (isCalendly) {
    return <BookingCalendly link={link} onRequestClose={onRequestClose} />;
  }
  if (isGoogle) {
    return <BookingGoogle link={link} onRequestClose={onRequestClose} />;
  }
};

const BookingCalendly = ({link, onRequestClose}) => {
  return (
    <PopupModal
      url={link}
      onModalClose={() => onRequestClose()}
      open
      rootElement={document.getElementById('root')}
    />
  );
};

const BookingGoogle = ({link, onRequestClose}) => {
  return (
    <Modal
      isOpen
      overlayClassName="modal-booking-overlay"
      className="modal-booking">
      <div className="booking-google-wrapper" onClick={() => onRequestClose()}>
        <iframe
          className="booking-google-iframe"
          src={link}
          title="book-slot"
        />
      </div>
    </Modal>
  );
};

export default BookingModal;
