import classnames from 'classnames';
import Button from 'components/Button';
import i18n from 'conf/i18n';
import PropTypes from 'prop-types';
import React from 'react';
import {stepsService} from 'services';
import './_Styles.scss';

const propTypes = {
  currentStep: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        indexOrder: PropTypes.number,
        labels: PropTypes.arrayOf(
          PropTypes.shape({
            content: PropTypes.string,
            language: PropTypes.string,
          })
        ),
      })
    ),
    type: PropTypes.string,
  }),
  setValue: PropTypes.func,
  value: PropTypes.any,
};

const defaultProps = {
  currentStep: {
    options: [],
  },
  setValue: () => {},
  value: null,
};

const MultipleChoice = ({currentStep, setValue, value}) => {
  const handleSelectChange = (e, option, selected) => {
    e.preventDefault();
    if (
      currentStep.type === stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
    ) {
      // add option id to value array
      if (selected === true) {
        setValue([...value, option]);
      }
      // remove option id from value array
      else {
        setValue(value.filter((e) => e.uid !== option.uid));
      }
    }
    if (
      currentStep.type === stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT
    ) {
      if (selected === true) {
        setValue(option);
      } else {
        setValue(null);
      }
    }
  };

  let inputType = 'radio';
  if (
    currentStep?.type === stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
  ) {
    inputType = 'checkbox';
  }

  return (
    <>
      <div className="select-wrapper">
        {currentStep.options
          ?.sort((a, b) => a.indexOrder - b.indexOrder)
          ?.map((o) => {
            const content =
              o.labels?.find((l) => l.language === i18n.language.toUpperCase())
                ?.content || o.content;
            const selected =
              currentStep.type ===
              stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
                ? value?.includes?.(o)
                : value === o;
            return (
              <div>
                <Button
                  className={classnames(
                    'survey-btn',
                    currentStep?.type ===
                      stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
                      ? 'checkbox-btn'
                      : 'select-btn',
                    {
                      selected,
                    }
                  )}
                  rounded={false}
                  onClick={(e) => handleSelectChange(e, o, !selected)}>
                  <input type={inputType} name="radio" checked={selected} />
                  {selected === false && <span className="checkmark" />}
                  {selected === true &&
                    (currentStep.type ===
                    stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT ? (
                      <i className="icon-tick"></i>
                    ) : (
                      <span className="select-fill"></span>
                    ))}
                  <span className="content">{content}</span>
                </Button>
              </div>
            );
          })}
      </div>
    </>
  );
};

MultipleChoice.propTypes = propTypes;
MultipleChoice.defaultProps = defaultProps;

export default MultipleChoice;
