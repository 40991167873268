import {generalActions} from 'actions';
import classnames from 'classnames';
import Loader from 'components/Loader';
import TextArea from 'components/TextAreaGroup';
import {toastDanger} from 'components/Toaster';
import {hasFlag} from 'helpers/bitwise';
import {bool, func, object} from 'prop-types';
import React, {useState} from 'react';
import {withTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import ModalIdentify from 'scenes/Standalone/components/ModalIdentify';
import {generalSelector} from 'selectors';
import {authService, commentService} from 'services';
import {F_OPTION_COMMENTS_ENABLED} from 'services/evolution';
import {
  F_EXTRA_ANONYMOUS_FEEDBACK_DISABLED,
  F_EXTRA_PREVENT_PERSONAL_DATA_PROMPT,
} from 'services/project';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('FormComment');

const propTypes = {
  evolution: object.isRequired,
  prototype: object,
  onSubmitComment: func,
  onSubmitStoreUpdate: func,
  replyTo: object,
  hideIcon: bool,
};

const defaultProps = {
  prototype: null,
  onSubmitComment: () => {},
  onSubmitStoreUpdate: null,
  replyTo: null,
  hideIcon: false,
};

const FormComment = ({
  evolution,
  prototype,
  onSubmitComment,
  onSubmitStoreUpdate,
  replyTo,
  hideIcon,
  t,
}) => {
  const dispatch = useDispatch();

  const project = useSelector((state) => generalSelector.getProject(state));
  const jimer = useSelector((state) => generalSelector.getJimer(state));

  const isAnonymousCommentsDisabled = hasFlag(
    F_EXTRA_ANONYMOUS_FEEDBACK_DISABLED,
    project.extraFlags
  );
  const isEmailFilled =
    authService.isIdentified(jimer) === true &&
    (jimer.personalEmail != null || jimer.externalEmail != null);

  const [inputComment, setInputComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModalIdentify, setShowModalIdentify] = useState(false);
  const [identifyType, setIdentifyType] = useState('afterComment');

  const updateEvolutionById = (evolutionId, evolution) =>
    dispatch(generalActions.uptEvolutionById(evolutionId, evolution));

  const handleSubmit = async (e) => {
    if (e != null) {
      e.preventDefault();
    }
    if (inputComment.length === 0) {
      return;
    }
    if (isAnonymousCommentsDisabled === true && isEmailFilled === false) {
      if (
        hasFlag(F_EXTRA_PREVENT_PERSONAL_DATA_PROMPT, project.extraFlags) ===
        true
      ) {
        return;
      }
      setIdentifyType('beforeComment');
      setShowModalIdentify(true);
      return;
    }
    setIsSubmitting(true);
    try {
      const comment = await commentService.createComment({
        message: inputComment,
        prototype: prototype != null ? prototype.uid : null,
        evolutionId: evolution.uid,
        replyToId: replyTo != null ? replyTo.uid : undefined,
      });

      if (onSubmitStoreUpdate == null) {
        if (evolution.comments != null) {
          if (replyTo == null) {
            updateEvolutionById(evolution.uid, {
              ...evolution,
              comments: evolution.comments.concat(comment),
            });
          } else {
            updateEvolutionById(evolution.uid, {
              ...evolution,
              comments: evolution.comments.map((c) =>
                c.uid === replyTo.uid
                  ? {...c, replies: c.replies.concat(comment)}
                  : c
              ),
            });
          }
        } else {
          updateEvolutionById(evolution.uid, {
            ...evolution,
            countComments: evolution.countComments + 1,
          });
        }
      } else {
        onSubmitStoreUpdate(comment);
      }
      onSubmitComment(comment);
      setIsSubmitting(false);
      setInputComment('');

      if (
        isEmailFilled === false &&
        hasFlag(F_EXTRA_PREVENT_PERSONAL_DATA_PROMPT, project.extraFlags) ===
          false
      ) {
        setIdentifyType('afterComment');
        setShowModalIdentify(true);
      }
      return;
    } catch (err) {
      toastDanger([
        'Wups...',
        'We could not save your comment, please try again!',
      ]);
      logger.error('Submitting comment failed with error', err);
      return setIsSubmitting(false);
    }
  };

  const disabled =
    hasFlag(F_OPTION_COMMENTS_ENABLED, evolution.optionsFlags) === false;
  const classNames = classnames('form-comment', {
    'is-disabled': disabled === true,
  });

  return (
    <>
      <form className={classNames} onSubmit={handleSubmit}>
        <TextArea
          placeholder={
            disabled === true
              ? t('common.commentDisabled')
              : t('common.addComment')
          }
          name="inputComment"
          value={inputComment}
          onChange={(e) => setInputComment(e.target.value)}
          autoComplete={false}
          required
          disabled={isSubmitting === true || disabled}
          onPressEnter={() => handleSubmit()}></TextArea>
        {hideIcon === false && disabled === false && (
          <button className="icon-wrapper">
            {isSubmitting === true ? (
              <Loader width="12px"></Loader>
            ) : (
              <i className="icon-send"></i>
            )}
          </button>
        )}
      </form>
      <ModalIdentify
        isOpen={showModalIdentify}
        type={identifyType}
        onRequestClose={() => setShowModalIdentify(false)}
      />
    </>
  );
};

FormComment.propTypes = propTypes;
FormComment.defaultProps = defaultProps;

export default withTranslation()(FormComment);
