import classnames from 'classnames';
import EvolutionStats from 'components/EvolutionStats';
import {MarkdownContent} from 'components/Markdown';
import i18n from 'conf/i18n';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import {Messenger} from 'managers/messenger';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import {
  ROUTE_STANDALONE_EVOLUTION_ID,
  ROUTE_WIDGET_EVOLUTION_ID,
} from 'router/routes.const';
import {F_SLOT_SNIPPET, F_SLOT_TOOLTIP} from 'scenes/Boosted';
import {
  F_OPTION_COMMENTS_ENABLED,
  F_OPTION_OPEN_WIDGET_ON_CLICK,
  F_OPTION_SHOW_ON_PORTAL,
} from 'services/evolution';
import Title from '../../components/Title';
import './_Styles.scss';
import {AppContext} from 'App';
import {useContext} from 'react';

const propTypes = {
  evolution: PropTypes.object.isRequired,
  preview: PropTypes.bool,
  isLastStep: PropTypes.bool,
  boosted: PropTypes.bool,
  widgetMode: PropTypes.bool,
  onSurveyEnd: PropTypes.func,
  currentStep: PropTypes.object.isRequired,
  showStatus: PropTypes.bool,
  showTimestamp: PropTypes.bool,
  onImageClick: PropTypes.func,
};

const defaultProps = {
  preview: false,
  isLastStep: false,
  boosted: false,
  widgetMode: false,
  onSurveyEnd: () => {},
  showStatus: false,
  showTimestamp: false,
  onImageClick: null,
};

const Text = ({
  evolution,
  preview,
  isLastStep,
  boosted,
  widgetMode,
  onSurveyEnd,
  currentStep,
  showStatus,
  showTimestamp,
  onImageClick,
}) => {
  const {theme} = useContext(AppContext);

  const shownOnPortal = hasFlag(
    F_OPTION_SHOW_ON_PORTAL,
    evolution?.optionsFlags
  );
  const handleTitleClick = async (e, fromMdReadMore = false) => {
    if (
      fromMdReadMore === true ||
      (boosted === true &&
        hasFlag(F_OPTION_OPEN_WIDGET_ON_CLICK, evolution.optionsFlags) === true)
    ) {
      e.preventDefault();
      e.stopPropagation();

      await onSurveyEnd();
      Messenger.sendOpenWidget({
        evolutionId: evolution.uid,
        page: 'evolution',
        startFrom: currentStep.uid,
      });
    }
  };
  const shouldTruncateContent = hasFlag([F_SLOT_SNIPPET], evolution.boostFlags);
  const shouldDisplayMdReadMore =
    shownOnPortal === true &&
    boosted === true &&
    hasFlags([F_SLOT_TOOLTIP, F_SLOT_SNIPPET], evolution.boostFlags, true) ===
      true;

  const isTitleWrappedInLink = hasFlag(
    evolution.optionsFlags,
    F_OPTION_COMMENTS_ENABLED
  );

  const question =
    currentStep?.texts?.find((t) => t.language === i18n.language.toUpperCase())
      ?.question || currentStep?.question;

  const mdContent =
    currentStep?.texts?.find((t) => t.language === i18n.language.toUpperCase())
      ?.mdContent || currentStep?.mdContent;

  const {text} = widgetMode === true ? theme ?? {} : {};
  const {bodyFontColor, bodyFontFamily} = text ?? {};

  return (
    <>
      <div className="infos">
        {isTitleWrappedInLink ? (
          <Link
            to={
              boosted === true
                ? '#'
                : widgetMode === true
                ? ROUTE_WIDGET_EVOLUTION_ID(evolution.uid)
                : ROUTE_STANDALONE_EVOLUTION_ID(evolution.uid)
            }
            className={classnames({
              'prevent-hover':
                boosted === true &&
                hasFlag(
                  F_OPTION_OPEN_WIDGET_ON_CLICK,
                  evolution.optionsFlags
                ) === false,
            })}>
            <Title
              question={question}
              evolution={evolution}
              showTimestamp={showStatus === false && showTimestamp === true}
              onTitleClick={handleTitleClick}
              widgetMode={widgetMode}
            />
          </Link>
        ) : (
          <Title
            showTimestamp={showStatus === false && showTimestamp === true}
            question={question}
            evolution={evolution}
            widgetMode={widgetMode}
          />
        )}
        <div
          className={classnames('text-block-wrapper', {
            'has-cta': currentStep.ctaUrl != null,
            boosted: boosted === true,
          })}
          style={{
            color:
              document.documentElement.style.getPropertyValue(
                '--boostedColorContent'
              ) || '#071331',
            fontSize:
              document.documentElement.style.getPropertyValue(
                '--boostedContentFontSize'
              ) || '14px',

            ...(bodyFontColor != null
              ? {
                  color: bodyFontColor,
                }
              : {}),
            ...(bodyFontFamily != null
              ? {
                  fontFamily: bodyFontFamily,
                }
              : {}),
          }}>
          <MarkdownContent
            content={mdContent}
            enableReadMore={shouldDisplayMdReadMore}
            enableTruncate={shouldTruncateContent}
            onClickReadMore={(e) => handleTitleClick(e, true)}
            onImageClick={onImageClick}
          />
          {boosted === false && (
            <EvolutionStats
              evolution={evolution}
              widgetMode={widgetMode}
              hideComment={isLastStep === false}
            />
          )}
        </div>
      </div>
    </>
  );
};

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;

export default Text;
