import {generalActions} from 'actions';
import Button from 'components/Button';
import Card from 'components/Card';
import Divider from 'components/Divider';
import Input from 'components/InputGroup';
import {toastDanger, toastSuccess} from 'components/Toaster';
import Toggle from 'components/Toggle';
import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {Trans} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {generalSelector} from 'selectors';
import {jimerService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';

const logger = new Swaler('StandaloneSettings');

const StandaloneSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const jimer = useSelector((state) => generalSelector.getJimer(state));

  const uptJimer = (jimer) => dispatch(generalActions.uptJimer(jimer));

  const [inputEmail, setInputEmail] = useState('');
  const [inputUsername, setInputUsername] = useState('');
  const [notificationOnEvolutionsDigest, setNotificationOnEvolutionsDigest] =
    useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setInputEmail(
      jimer.personalEmail != null
        ? jimer.personalEmail
        : jimer.externalEmail != null
        ? jimer.externalEmail
        : ''
    );
    setInputUsername(jimer.username == null ? '' : jimer.username);
    setNotificationOnEvolutionsDigest(jimer.notificationOnEvolutionsDigest);
  }, [
    jimer.notificationOnEvolutionsDigest,
    jimer.personalEmail,
    jimer.username,
    jimer.externalEmail,
  ]);

  const isEnabled = () =>
    (jimer.username != null && jimer.username !== inputUsername) ||
    (jimer.personalEmail != null && jimer.personalEmail !== inputEmail) ||
    (jimer.personalEmail == null && inputEmail.length !== 0) ||
    notificationOnEvolutionsDigest !== jimer.notificationOnEvolutionsDigest;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const jimer = await jimerService.updateJimerPersonalData({
        email: inputEmail.length === 0 ? null : inputEmail,
      });

      uptJimer(jimer);
      toastSuccess('Account settings saved!');
    } catch (err) {
      logger.error('Updating jimer data failed with error ', err);
      toastDanger([
        'Wups...',
        'We could not save your information, please try again!',
      ]);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="s-standalone-settings fade-in-bottom">
      <Row center="xs">
        <Col
          xs={12}
          md={7}
          xl={7}
          className="card-account-wrapper max-width-600">
          <Button className="btn-back" muted onClick={() => history.goBack()}>
            <i className="icon-chevron-left"></i>
          </Button>
          <Card>
            <div className="title">
              <Trans i18nKey="others.settingsTitle"></Trans>
            </div>
            <p>
              <Trans i18nKey="others.settingsParagraph"></Trans>
            </p>
            <Divider dark></Divider>
            <form onSubmit={handleSubmit}>
              <div className="input-wrapper">
                <div className="label-custom">
                  <Trans i18nKey="common.email"></Trans>
                </div>
                <Input
                  name="inputEmail"
                  value={inputEmail}
                  disabled={isSubmitting}
                  onChange={(e) => setInputEmail(e.target.value)}></Input>
              </div>
              <Button primary loading={isSubmitting} disabled={!isEnabled()}>
                <Trans i18nKey="common.save"></Trans>
              </Button>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default StandaloneSettings;
