import React from 'react';
import {
  STEP_TYPE_CONCEPT_TEST,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';
import PropTypes from 'prop-types';
import MultipleChoice from '../../steps/MultipleChoice';
import ConceptTest from '../../steps/ConceptTest';
import NpsComponent from '../../steps/Nps';
import Slider from '../../steps/Slider';
import OpenQuestion from '../../steps/OpenQuestion';

const propTypes = {
  evolution: PropTypes.object.isRequired,
  currentStep: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  value: PropTypes.any,
  isLastStep: PropTypes.bool,
  isLastTotalStep: PropTypes.bool,
  setStepIndex: PropTypes.func,
  boosted: PropTypes.bool,
  onConceptTestClick: PropTypes.func,
  widgetMode: PropTypes.bool,
  totalStepsLength: PropTypes.number,
  onDismiss: PropTypes.func,
};

const defaultProps = {
  value: null,
  isLastStep: false,
  isLastTotalStep: false,
  setStepIndex: () => {},
  boosted: false,
  onConceptTestClick: () => {},
  widgetMode: false,
  totalStepsLength: 0,
  onDismiss: () => {},
};

const SurveyContent = ({
  evolution,
  currentStep,
  setValue,
  handleSubmit,
  value,
  isLastStep,
  isLastTotalStep,
  setStepIndex,
  boosted,
  onConceptTestClick,
  widgetMode,
  totalStepsLength,
  onDismiss,
}) => {
  let content;

  if (
    [
      STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
      STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    ].includes(currentStep.type)
  ) {
    content = (
      <MultipleChoice
        currentStep={currentStep}
        evolution={evolution}
        setValue={setValue}
        handleSubmit={handleSubmit}
        value={value}
        isLastTotalStep={isLastTotalStep}
        totalStepsLength={totalStepsLength}
        onDismiss={onDismiss}
      />
    );
  } else if (currentStep.type === STEP_TYPE_CONCEPT_TEST) {
    content = (
      <ConceptTest
        currentStep={currentStep}
        isLastStep={isLastStep}
        isLastTotalStep={isLastTotalStep}
        setStepIndex={setStepIndex}
        boosted={boosted}
        onConceptTestClick={onConceptTestClick}
        widgetMode={widgetMode}
        evolution={evolution}
        onDismiss={onDismiss}
      />
    );
  } else if (currentStep.type === STEP_TYPE_NPS) {
    content = (
      <div key={currentStep?.uid} className="fade-in-right">
        <NpsComponent
          currentStep={currentStep}
          evolution={evolution}
          handleSubmit={handleSubmit}
          setValue={setValue}
          value={value}
          isLastTotalStep={isLastTotalStep}
          totalStepsLength={totalStepsLength}
          onDismiss={onDismiss}
        />
      </div>
    );
  } else if (currentStep.type === STEP_TYPE_SLIDER) {
    content = (
      <Slider
        currentStep={currentStep}
        evolution={evolution}
        handleSubmit={handleSubmit}
        setValue={setValue}
        value={value}
        isLastTotalStep={isLastTotalStep}
        totalStepsLength={totalStepsLength}
        onDismiss={onDismiss}
      />
    );
  } else if (currentStep.type === STEP_TYPE_TEXT_LONG) {
    content = (
      <OpenQuestion
        currentStep={currentStep}
        evolution={evolution}
        handleSubmit={handleSubmit}
        setValue={setValue}
        value={value}
        isLastTotalStep={isLastTotalStep}
        totalStepsLength={totalStepsLength}
        onDismiss={onDismiss}
      />
    );
  }

  return <div key={currentStep?.uid}>{content}</div>;
};

SurveyContent.propTypes = propTypes;
SurveyContent.defaultProps = defaultProps;

export default SurveyContent;
