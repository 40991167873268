import Button from 'components/Button';
import Divider from 'components/Divider';
import {hasFlag} from 'helpers/bitwise';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {
  ROUTE_STANDALONE_PREVIEW,
  ROUTE_WIDGET_PREVIEW,
} from 'router/routes.const';
import {F_OPTION_TOUR_STEP_SKIPPABLE} from 'services/evolution';
import './_Styles.scss';

const propTypes = {
  currentStep: PropTypes.shape({
    conceptThumbnailUrl: PropTypes.string,
  }),
  isLastStep: PropTypes.bool,
  isLastTotalStep: PropTypes.bool,
  setStepIndex: PropTypes.func,
  stepIndex: PropTypes.number,
  showStatus: PropTypes.bool,
  showTimestamp: PropTypes.bool,
  onDismiss: PropTypes.func,
};

const defaultProps = {
  currentStep: {
    conceptThumbnailUrl: '',
  },
  isLastStep: false,
  isLastTotalStep: false,
  setStepIndex: () => {},
  stepIndex: 0,
  showStatus: false,
  showTimestamp: false,
  onDismiss: () => {},
};

const ConceptTest = ({
  currentStep,
  isLastStep,
  isLastTotalStep,
  setStepIndex,
  stepIndex,
  boosted,
  onConceptTestClick,
  widgetMode,
  evolution,
  onDismiss,
}) => {
  const history = useHistory();

  // const hasSkipButton =
  //   hasFlag(F_OPTION_TOUR_STEP_SKIPPABLE, evolution.optionsFlags) === true;
  const hasSkipButton = false;

  const handleClickConceptTest = () => {
    const isFigmaOrInvision = ['invis.io', 'invisionapp.com', 'figma.com'].some(
      (domain) => currentStep.interactiveLink.includes(domain)
    );

    if (boosted === true) {
      return onConceptTestClick(currentStep);
    }
    if (isFigmaOrInvision === true) {
      return history.push(
        widgetMode === true
          ? ROUTE_WIDGET_PREVIEW(evolution.uid, currentStep.uid)
          : ROUTE_STANDALONE_PREVIEW(evolution.uid, currentStep.uid)
      );
    } else {
      window.open(currentStep.interactiveLink, '_blank');
    }
  };

  return (
    <>
      <div className="concept-test-main-wrapper">
        <div className="concept-test-wrapper">
          <div
            className="concept-test-thumbnail"
            style={
              currentStep.conceptThumbnailUrl != null
                ? {backgroundImage: `url(${currentStep.conceptThumbnailUrl})`}
                : {}
            }
          />
          <div
            className="concept-test-overlay"
            onClick={handleClickConceptTest}
          />
          <div className="btn-play" onClick={handleClickConceptTest}>
            <i className="icon-play" />
          </div>
        </div>
      </div>
      {isLastStep === false && isLastTotalStep === false && (
        <>
          <Divider dark />
          <div className="concept-test-actions-wrapper">
            {hasSkipButton && (
              <Button
                className="btn-skip"
                style={{
                  backgroundColor:
                    document.documentElement.style.getPropertyValue(
                      '--boostedSecondaryColor'
                    ) || '#409af8',
                }}
                onClick={onDismiss}
                primary>
                <Trans i18nKey="common.skip" />
              </Button>
            )}
            <Button
              className="btn-step-next"
              style={{
                backgroundColor:
                  document.documentElement.style.getPropertyValue(
                    '--boostedSecondaryColor'
                  ) || '#409af8',
              }}
              onClick={() => setStepIndex(stepIndex + 1)}
              primary>
              <Trans i18nKey="common.next" />
            </Button>
          </div>
        </>
      )}
    </>
  );
};

ConceptTest.propTypes = propTypes;
ConceptTest.defaultProps = defaultProps;

export default ConceptTest;
