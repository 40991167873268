import Lottie from 'lottie-react';
import React from 'react';
import animation from '../../animations/success.json';

const Success = ({onSuccessCompleted}) => {
  return (
    <div className="success-wrapper">
      <Lottie
        animationData={animation}
        loop={false}
        style={{
          width: '100%',
          height: '100%',
        }}
        onComplete={onSuccessCompleted}
      />
    </div>
  );
};

export default Success;
