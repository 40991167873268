import {generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import Card from 'components/Card';
import FormComment from 'components/FormComment';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import {hasFlag} from 'helpers/bitwise';
import React from 'react';
import {Trans} from 'react-i18next';
import {connect} from 'react-redux';
import {commentService, jimerService} from 'services';
import {F_OPTION_COMMENTS_ENABLED} from 'services/evolution';
import {Swaler} from 'swaler';
import './_styles.scss';

dayjs.extend(relativeTime);
dayjs.extend(utc);

class EvolutionComments extends React.Component {
  constructor() {
    super();
    this.state = {
      inputReply: '',
      commentToReply: null,
    };
  }

  handleInputChange = ({target}) =>
    this.setState({[target.name]: target.value});
  handleCommentLike = (like, commentReply) => {
    const {evolution, uptEvolutionById} = this.props;

    let updatedComments = [];

    if (commentReply != null) {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === commentReply.uid
          ? {
              ...c,
              replies: c.replies.map((r) =>
                r.uid === like.comment.uid
                  ? {...r, likes: r.likes.concat({uid: like.uid}), myLike: like}
                  : r
              ),
            }
          : c);
    } else {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === like.comment.uid
          ? {...c, likes: c.likes.concat({uid: like.uid}), myLike: like}
          : c);
    }
    uptEvolutionById(evolution.uid, {...evolution, comments: updatedComments});
  };
  handleCommentUnlike = (commentId, commentLikeId, commentReply) => {
    const {evolution, uptEvolutionById} = this.props;
    let updatedComments = [];

    if (commentReply != null) {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === commentReply.uid
          ? {
              ...c,
              replies: c.replies.map((r) =>
                r.uid === commentId
                  ? {
                      ...r,
                      likes: c.likes.filter((l) => l.uid !== commentLikeId),
                      myLike: null,
                    }
                  : r
              ),
            }
          : c);
    } else {
      updatedComments = evolution.comments.map((c) /** comment */ =>
        c.uid === commentId
          ? {
              ...c,
              likes: c.likes.filter((l) => l.uid !== commentLikeId),
              myLike: null,
            }
          : c);
    }
    uptEvolutionById(evolution.uid, {...evolution, comments: updatedComments});
  };

  render() {
    const {evolution, showCount = false} = this.props;
    const {commentToReply} = this.state;
    const {comments = []} = evolution;
    const disabled =
      hasFlag(F_OPTION_COMMENTS_ENABLED, evolution.optionsFlags) === false;
    const classNames = classnames('evolution-comments', {
      'is-disabled': disabled === true,
    });

    return (
      <div className={classNames}>
        {showCount === true && disabled === false && (
          <div className="count">
            <span>
              {comments.length}{' '}
              <Trans i18nKey="common.comments" count={comments.length}></Trans>
            </span>
          </div>
        )}
        <div className="comment-list">
          {disabled === false ? (
            comments
              .sort(
                (a, b) =>
                  new Date(a.createdAt).getTime() -
                  new Date(b.createdAt).getTime()
              )
              .map((c) => (
                <Comment
                  key={c.id}
                  comment={c}
                  onLike={this.handleCommentLike}
                  onUnlike={this.handleCommentUnlike}
                  onReply={() => this.setState({commentToReply: c})}>
                  {c.replies != null && c.replies.length !== 0 && (
                    <div className="replies">
                      {c.replies
                        .sort(
                          (a, b) =>
                            new Date(a.createdAt).getTime() -
                            new Date(b.createdAt).getTime()
                        )
                        .map((r) => (
                          <Comment
                            key={r.uid}
                            comment={r}
                            onLike={(like) => this.handleCommentLike(like, c)}
                            onUnlike={(commentId, likeId) =>
                              this.handleCommentUnlike(commentId, likeId, c)
                            }
                            onReply={() =>
                              this.setState({commentToReply: c})
                            }></Comment>
                        ))}
                    </div>
                  )}
                  {commentToReply != null && commentToReply.uid === c.uid && (
                    <FormComment
                      evolution={evolution}
                      replyTo={c}></FormComment>
                  )}
                </Comment>
              ))
          ) : (
            <Trans i18nKey="common.commentDisabled"></Trans>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  uptEvolutionById: (evolutionId, evolution) =>
    dispatch(generalActions.uptEvolutionById(evolutionId, evolution)),
});
export default connect(null, mapDispatchToProps)(EvolutionComments);

class Comment extends React.Component {
  logger = new Swaler('Comment');

  constructor() {
    super();
    this.state = {
      isSubmittingLike: null,
    };
  }

  handleLike = async (commentId) => {
    const {onLike} = this.props;

    this.setState({isSubmittingLike: true});
    try {
      const like = await commentService.createCommentLike(commentId);

      this.setState({isSubmittingLike: false});
      onLike(like);
    } catch (err) {
      this.logger.error('Submitting comment like failed with error ', err);
      this.setState({isSubmittingLike: false});
    }
  };
  handleUnLike = async (commentId, like) => {
    const {onUnlike} = this.props;

    this.setState({isSubmittingLike: true});
    try {
      await commentService.deleteCommentLike(commentId, like.uid);

      this.setState({isSubmittingLike: false});
      onUnlike(commentId, like.uid);
    } catch (err) {
      this.logger.error('Deleting comment like failed with error ', err);
      this.setState({isSubmittingLike: false});
    }
  };

  render() {
    const {comment, children, onReply} = this.props;
    const {isSubmittingLike} = this.state;
    const isStaff = comment.user != null;
    const isJimer = comment.jimer != null ? true : false;
    const author = isJimer === true ? comment.jimer : comment.user;
    const username =
      isJimer === false
        ? author?.username
        : jimerService.hasUsernameGenerated(author?.username) === true &&
          author?.externalUsername != null
        ? author?.externalUsername
        : author?.username;

    return (
      <Card muted className="evolution-comment">
        <div className="author-date-wrapper">
          <div className={classnames('author', {'is-staff': isStaff === true})}>
            {author != null ? username : 'Anonymous user'}
            {isStaff && <i className="icon-star" title={`Staff`}></i>}
          </div>
          <div className="date">{dayjs(comment.createdAt).utc().fromNow()}</div>
        </div>
        <div className="message">{comment.message}</div>
        <div className="actions">
          <Button
            iconLeft="icon-thumbs-up"
            muted
            thin
            className="likes"
            active={comment.myLike != null}
            onClick={() => {
              if (comment.myLike != null) {
                this.handleUnLike(comment.uid, comment.myLike);
              } else {
                this.handleLike(comment.uid);
              }
            }}
            loading={isSubmittingLike === true}>
            {comment.likes.length}
          </Button>
          <Button thin light className="btn-reply" onClick={onReply}>
            <Trans i18nKey="common.reply"></Trans>
          </Button>
        </div>
        {children}
      </Card>
    );
  }
}
