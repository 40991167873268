import classnames from 'classnames';
import {hasFlag} from 'helpers/bitwise';
import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link, useRouteMatch} from 'react-router-dom';
import {
  ROUTE_STANDALONE_FEED,
  ROUTE_STANDALONE_FEEDBACK,
  ROUTE_STANDALONE_ROADMAP,
  ROUTE_STANDALONE_SETTINGS,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {authService} from 'services';
import {F_PAGE_FEED, F_PAGE_FEEDBACK, F_PAGE_ROADMAP} from 'services/project';
import ModalIdentify from '../ModalIdentify';
import './_Styles.scss';

const TAB_FEED = 'FEED';
const TAB_ROADMAP = 'ROADMAP';
const TAB_FEEDBACK = 'FEEDBACK';

const TopNav = ({hideOnMobile}) => {
  const jimer = useSelector((state) => generalSelector.getJimer(state));
  const project = useSelector((state) => generalSelector.getProject(state));
  const hideTopNav = useSelector((state) =>
    generalSelector.getHideTopNav(state)
  );

  const [showModalIdentify, setShowModalIdentify] = useState(false);
  const [activeTab, setActiveTab] = useState(null);

  const {path} = useRouteMatch();

  useEffect(() => {
    const isActive = (paths) => {
      return [].concat(paths).includes(path);
    };

    if (isActive([ROUTE_STANDALONE_FEED])) {
      setActiveTab(TAB_FEED);
    } else if (isActive([ROUTE_STANDALONE_ROADMAP])) {
      setActiveTab(TAB_ROADMAP);
    } else if (isActive([ROUTE_STANDALONE_FEEDBACK])) {
      setActiveTab(TAB_FEEDBACK);
    }
  }, [path]);

  const handleShowHideModalIdentify = (show = !showModalIdentify) => {
    setShowModalIdentify(show);
  };

  const identifiedLetter =
    authService.isIdentified(jimer) === true
      ? jimer.personalEmail != null
        ? jimer.personalEmail.slice(0, 1)
        : jimer.externalEmail.slice(0, 1)
      : null;

  const showRoadmap = hasFlag(F_PAGE_ROADMAP, project.portalContentFlags);
  const showFeedback = hasFlag(F_PAGE_FEEDBACK, project.portalContentFlags);
  const showFeed = hasFlag(F_PAGE_FEED, project.portalContentFlags);
  const allShow = [showRoadmap, showFeed, showFeedback];

  const sectionsStr = project.portalSectionOrder || 'feed;roadmap;feedback';
  const sections = sectionsStr.split(';');
  const filteredSections = sections.filter((s) => {
    if (showRoadmap === false && s === 'roadmap') {
      return false;
    }
    if (showFeedback === false && s === 'feedback') {
      return false;
    }
    if (showFeed === false && s === 'feed') {
      return false;
    }
    return true;
  });

  const firstSection = filteredSections[0];

  const renderNav = () => {
    return (
      <>
        {filteredSections.map((s, index) => {
          if (s === 'feed') {
            return (
              <Link
                to={ROUTE_STANDALONE_FEED}
                className={classnames('item', {
                  'is-active': activeTab === TAB_FEED,
                })}>
                {index === 0 && <i className="icon-feed" />}
                Feed
              </Link>
            );
          }
          if (s === 'roadmap') {
            return (
              <Link
                to={ROUTE_STANDALONE_ROADMAP}
                className={classnames('item', {
                  'is-active': activeTab === TAB_ROADMAP,
                })}>
                {index === 0 && <i className="icon-feed" />}
                <Trans i18nKey="others.upcomingTabButtonRoadmap" />
              </Link>
            );
          }
          if (s === 'feedback') {
            return (
              <Link
                to={ROUTE_STANDALONE_FEEDBACK}
                className={classnames('item', {
                  'is-active': activeTab === TAB_FEEDBACK,
                })}>
                {index === 0 && <i className="icon-feed" />}
                <Trans i18nKey="others.topNavGiveFeedback" />
              </Link>
            );
          }
          return <></>;
        })}
      </>
    );
  };

  return (
    <div
      className={classnames('standalone-home-top-nav', {
        'is-hidden-mobile': hideOnMobile === true,
        'has-only-one-item': filteredSections.length <= 1,
        'no-padding-top': hideTopNav === true,
      })}>
      <Row center="xs" className="content">
        {hideTopNav === false && (
          <Col className="box left" xs={6} xl={3}>
            <Link
              to={
                firstSection === 'feed'
                  ? ROUTE_STANDALONE_FEED
                  : firstSection === 'roadmap'
                  ? ROUTE_STANDALONE_ROADMAP
                  : firstSection === 'feedback'
                  ? ROUTE_STANDALONE_FEEDBACK
                  : '#'
              }
              className="project-wrapper">
              {project.portalLogoUrl != null ? (
                <div className="project-logo">
                  <img
                    src={project.portalLogoUrl}
                    alt={`${project.name} logo`}
                  />
                </div>
              ) : (
                <div className="project-name">{project.name}</div>
              )}
            </Link>
          </Col>
        )}
        {allShow.filter((s) => s === true).length > 1 && (
          <Col className="box item-wrapper middle max-width-600" xl={6}>
            {renderNav()}
          </Col>
        )}
        {hideTopNav === false && (
          <Col className="box right" xs={6} xl={3}>
            {identifiedLetter != null ? (
              <Link to={ROUTE_STANDALONE_SETTINGS} className="avatar">
                {identifiedLetter}
              </Link>
            ) : (
              <div
                className="avatar not-identified"
                onClick={() => handleShowHideModalIdentify(true)}
              />
            )}
          </Col>
        )}
      </Row>
      <ModalIdentify
        isOpen={showModalIdentify}
        onRequestClose={() => handleShowHideModalIdentify(false)}
      />
    </div>
  );
};

export default TopNav;
