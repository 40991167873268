import {generalActions} from 'actions';
import classnames from 'classnames';
import Button from 'components/Button';
import CardPush from 'components/CardPush';
import Loader from 'components/Loader';
import {toastDanger, toastInfo} from 'components/Toaster';
import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {connect, useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams, useRouteMatch} from 'react-router';
import {
  ROUTE_STANDALONE_FEED,
  ROUTE_STANDALONE_FEEDBACK,
  ROUTE_WIDGET_EVOLUTION_ID,
  ROUTE_WIDGET_FEED,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {evolutionService} from 'services';
import {Swaler} from 'swaler';
import './_Styles.scss';
import PostDetails from 'scenes/Widget/Evolution/components/PostDetails';

const logger = new Swaler('StandaloneEvolution');

const StandaloneEvolution = ({getEvolution}) => {
  const history = useHistory();
  const params = useParams();
  const {path} = useRouteMatch();
  const {evolutionId} = params;

  const project = useSelector((state) => generalSelector.getProject(state));

  const dispatch = useDispatch();

  const uptEvolutionById = (evolutionId, evolution) =>
    dispatch(generalActions.uptEvolutionById(evolutionId, evolution));

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchEvolution = async () => {
      try {
        const evolution = await evolutionService.getEvolutionById(evolutionId, {
          relations: ['comments', 'comments.likes'],
        });

        uptEvolutionById(evolutionId, evolution);
      } catch (err) {
        logger.error('Fetching evolution failed with err', err);
        return toastDanger([
          'Wups...',
          'We could not fetch this evolution, please try again later!',
        ]);
      }
    };

    const setup = async () => {
      const evolution = getEvolution(evolutionId);

      if (evolution == null) {
        toastInfo(
          [
            'Evolution not found',
            'Wups... it seems that this evolution no longer exists or you do not have access to it!',
          ],
          {autoClose: 5000}
        );
        return history.push(ROUTE_STANDALONE_FEEDBACK);
      }
      await fetchEvolution();
      setIsLoading(false);
    };

    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evolutionId]);

  const classNames = classnames('s-standalone-evolution', {
    'is-loading': isLoading === true,
    'is-white-labeled': project.whiteLabeling === true,
  });
  const isWidgetMode = () => path === ROUTE_WIDGET_EVOLUTION_ID();
  const evolution = getEvolution(evolutionId);

  if (evolution == null) {
    history.push(ROUTE_STANDALONE_FEED);
    return <></>;
  }
  if (evolution == null) {
    return;
  }
  if (isLoading === true) {
    return (
      <div className={classNames}>
        <Loader width="24px"></Loader>
      </div>
    );
  }

  const isOldVersionPost = !(evolution?.steps?.[0]?.blocks?.length > 0);

  return (
    <div className={classNames}>
      <Row center="xs">
        <Col xs={12} md={7} xl={7} className="col-card max-width-600">
          <Button
            muted
            onClick={() => {
              if (history.action === 'POP') {
                return history.push(
                  isWidgetMode() === true
                    ? ROUTE_WIDGET_FEED
                    : ROUTE_STANDALONE_FEED
                );
              } else {
                return history.goBack();
              }
            }}
            className="btn-back">
            <i className="icon-chevron-left"></i>
          </Button>
          {isOldVersionPost === true ? (
            <CardPush evolution={evolution} withComments />
          ) : (
            <PostDetails post={evolution} standalone />
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  getEvolution: (evolutionId) =>
    generalSelector.getEvolutionById(state, evolutionId),
});

export default connect(mapStateToProps)(StandaloneEvolution);
