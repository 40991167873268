import React from 'react';
import StandaloneButtonSubmitRequest from 'scenes/Standalone/components/ButtonSubmitRequest';
import StandaloneCardSubscribe from 'scenes/Standalone/components/CardSubscribe';
import StandaloneFooter from 'scenes/Standalone/components/Footer';
import StandaloneTopNav from 'scenes/Standalone/components/TopNav';
import WidgetFooter from 'scenes/Widget/components/Footer';
import WidgetHeader from 'scenes/Widget/components/Header';

export const IntercomBasicLayout = ({component: Component, ...props}) => (
  <div className="layout layout-widget-basic">
    <WidgetHeader isIntercom />
    <Component {...props} isIntercom />
    <WidgetFooter />
  </div>
);

export const WidgetBasicLayout = ({component: Component, ...props}) => (
  <div className="layout layout-widget-basic">
    <WidgetHeader />
    <Component {...props} />
    <WidgetFooter />
  </div>
);

export const StandaloneBasicLayout = ({
  component: Component,
  layoutProps = {},
  ...props
}) => {
  const {
    hideBtnSubmitRequest = false,
    hideTopNavOnMobile = false,
    hideBtnSubmitRequestOnMobile = false,
    showCardSubscribe = false,
  } = layoutProps;

  return (
    <div className="layout layout-standalone-basic">
      <StandaloneTopNav hideOnMobile={hideTopNavOnMobile} />
      {hideBtnSubmitRequest === false && (
        <StandaloneButtonSubmitRequest
          hideOnMobile={hideBtnSubmitRequestOnMobile}
          fixed
        />
      )}
      {showCardSubscribe === true && <StandaloneCardSubscribe />}
      <Component {...props} />
      <StandaloneFooter />
    </div>
  );
};

export const EmptyLayout = ({component: Component, ...props}) => (
  <div className="layout layout-empty">
    <Component {...props} />
  </div>
);

export const EmptyIntercomLayout = ({component: Component, ...props}) => (
  <div className="layout layout-empty">
    <Component {...props} isIntercom />
  </div>
);
