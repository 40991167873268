import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import './_Styles.scss';
import {AppContext} from 'App';
import {useContext} from 'react';

const Title = ({
  evolution,
  question,
  showTimestamp,
  onTitleClick,
  widgetMode,
}) => {
  const {theme} = useContext(AppContext);

  const fromNow = dayjs(evolution.lastStepChangeAt).fromNow();

  const {text} = widgetMode === true ? theme ?? {} : {};
  const {titleFontColor, titleFontFamily} = text ?? {};

  return (
    <div
      key={evolution.boostedTitleFontSize}
      className="step-title"
      onClick={onTitleClick}>
      <div
        className="title"
        style={{
          ...(titleFontColor != null
            ? {
                color: titleFontColor,
              }
            : {}),
          ...(titleFontFamily != null
            ? {
                fontFamily: titleFontFamily,
              }
            : {}),
        }}>
        {question}
      </div>
      {showTimestamp === true && (
        <small
          className="timestamp"
          title={dayjs(evolution.lastStepChangeAt).format(
            'HH:mm - DD/MM/YYYY'
          )}>
          {fromNow === 'il y a quelques secondes' ? "À l'instant" : fromNow}
        </small>
      )}
    </div>
  );
};

Title.propTypes = {
  evolution: PropTypes.object.isRequired,
  question: PropTypes.string.isRequired,
  showTimestamp: PropTypes.bool,
  onTitleClick: PropTypes.func,
  widgetMode: PropTypes.bool,
};

Title.defaultProps = {
  showTimestamp: false,
  onTitleClick: () => {},
  widgetMode: false,
};

export default Title;
