import classnames from 'classnames';
import React from 'react';
import Button from '../Button';
import {object} from 'prop-types';
import './_Styles.scss';
import i18n from 'conf/i18n';

const propTypes = {
  tag: object.isRequired,
};
const defaultProps = {};

const TagButton = ({tag}) => {
  const className = classnames('btn-tag', {
    'has-color': tag.color != null,
  });
  const name =
    tag.texts?.find((t) => t.language === i18n.language.toUpperCase())?.name ||
    tag.name;

  return (
    <Button type="button" className={className}>
      {tag.color != null && (
        <div
          className="bg-color"
          style={{
            backgroundColor: tag.color,
          }}></div>
      )}
      <div
        className="tag-content-wrapper"
        style={tag.color != null ? {color: tag.color} : undefined}>
        {name}
      </div>
    </Button>
  );
};

TagButton.propTypes = propTypes;
TagButton.defaultProps = defaultProps;

export default TagButton;
