import classnames from 'classnames';
import {EVOLUTION_STEP_RELEASE} from 'constants/evolution';
import React from 'react';
import {useSelector} from 'react-redux';
import {useRouteMatch} from 'react-router';
import {
  ROUTE_WIDGET_EVOLUTION_ID,
  ROUTE_WIDGET_FEED,
  ROUTE_WIDGET_FEEDBACK,
  ROUTE_WIDGET_ROADMAP,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import './_Styles.scss';
import {AppContext} from 'App';
import {useContext} from 'react';

const WidgetBackgroundCircleConnected = () => {
  const match = useRouteMatch();

  const {theme} = useContext(AppContext);
  const {general} = theme ?? {};
  const {background} = general ?? {};
  const {type, shape, primaryColor, secondaryColor} = background ?? {};

  const evolutions = useSelector((state) =>
    generalSelector.getEvolutions(state)
  );
  const isEmpty = () => {
    if (
      match.path === ROUTE_WIDGET_FEED &&
      evolutions.filter((e) => e.step === EVOLUTION_STEP_RELEASE).length === 0
    ) {
      return true;
    }
    if (
      match.path === ROUTE_WIDGET_FEEDBACK &&
      evolutions.filter((e) => e.step !== EVOLUTION_STEP_RELEASE).length === 0
    ) {
      return true;
    }
    if (match.path === ROUTE_WIDGET_ROADMAP && evolutions.length === 0) {
      return true;
    }
    return false;
  };
  const classNames = classnames('widget-bg-circle-wrapper', {
    'for-feed': [ROUTE_WIDGET_FEED].includes(match.path),
    'for-feedback': [ROUTE_WIDGET_FEEDBACK].includes(match.path),
    'for-evolution': [ROUTE_WIDGET_EVOLUTION_ID()].includes(match.path),
    'for-roadmap': [ROUTE_WIDGET_ROADMAP].includes(match.path),
    'is-empty': isEmpty(),
  });

  if (type != null && type === 'solid') {
    return <></>;
  } else if (type != null && type === 'gradient') {
    return (
      <div
        className={classnames('widget-gradient', {})}
        style={{
          backgroundImage: `linear-gradient(213.36deg, ${secondaryColor}, transparent)`,
          backgroundColor: primaryColor,
        }}></div>
    );
  }
  return (
    <div className={classNames}>
      <div className="widget-bg-circle"></div>
    </div>
  );
};

export default WidgetBackgroundCircleConnected;
