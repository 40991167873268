import classNames from 'classnames';
import EvolutionComments from 'components/EvolutionComments';
import EvolutionStats from 'components/EvolutionStats';
import FormComment from 'components/FormComment';
import {toastSuccess} from 'components/Toaster';
import i18n from 'conf/i18n';
import {hasFlag} from 'helpers/bitwise';
import {bool, object} from 'prop-types';
import React, {useContext} from 'react';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {F_OPTION_COMMENTS_ENABLED} from 'services/evolution';
import {WIDGET_LANG_AUTO} from 'services/project';
import {Post} from 'shared/front/components/Post';
import './_Styles.scss';
import {Messenger} from 'managers/messenger';
import {AppContext} from 'App';

const propTypes = {
  post: object.isRequired,
  standalone: bool,
};

const defaultProps = {
  standalone: false,
};

const PostDetails = ({post, standalone}) => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const {addFontFamily} = useContext(AppContext);

  const handleSubmitComment = () => {
    toastSuccess([
      <Trans i18nKey="others.commentPostedToastSuccessTitle"></Trans>,
      <Trans i18nKey="others.commentPostedToastSuccessBody"></Trans>,
    ]);
  };

  const hasCommentsEnabled = hasFlag(
    F_OPTION_COMMENTS_ENABLED,
    post.optionsFlags
  );

  const theme = project.changelogStyle;

  return (
    <div className={classNames('post-details', {standalone})}>
      <div className="post-scrollable-wrapper">
        <Post
          post={post}
          hasCommentsEnabled={true}
          hasLikesEnabled={true}
          language={
            project.widgetLanguage !== WIDGET_LANG_AUTO
              ? project.widgetLanguage
              : i18n.language.toUpperCase()
          }
          theme={theme}
          isPreview={false}
          onLaunchPoke={({pokeId, defaultUrl}) => {
            Messenger.sendLaunchPoke({
              pokeId,
              defaultUrl,
            });
          }}
          onUrlClick={(url) => {
            Messenger.sendOpenCtaUrl(url);
          }}
          onImageClick={(src) => {
            Messenger.sendOpenModalImage(src);
          }}
          PostBlockSocialsContent={() => (
            <EvolutionStats evolution={post} widgetMode />
          )}
          addFontFamily={addFontFamily}
        />
        {post.comments?.length > 0 && hasCommentsEnabled === true && (
          <div className="comments-wrapper">
            <EvolutionComments evolution={post} />
          </div>
        )}
      </div>
      {hasCommentsEnabled === true && (
        <div
          className={classNames('form-comment-wrapper', {
            'evolution-has-no-comments': post.comments?.length === 0,
          })}>
          <FormComment evolution={post} onSubmitComment={handleSubmitComment} />
        </div>
      )}
    </div>
  );
};

PostDetails.propTypes = propTypes;
PostDetails.defaultProps = defaultProps;

export default PostDetails;
