import classnames from 'classnames';
import Divider from 'components/Divider';
import {hasFlag} from 'helpers/bitwise';
import React from 'react';
import {useQuery} from 'react-query';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {F_OPTION_PORTAL_DISPLAY_ROADMAP} from 'services/evolution';
import {getTags} from 'services/tags';
import {Swaler} from 'swaler';
import Column from './components/Column';
import './_Styles.scss';

const logger = new Swaler('Roadmap');

const contextOrder = ['IDEATION', 'PREVIEW'];

export const Roadmap = ({enablePostCollapse = false, widgetMode}) => {
  const evolutions = useSelector((state) =>
    generalSelector.getEvolutions(state)
  );

  const {data: tags = [], isLoading} = useQuery({
    queryKey: 'tags',
    queryFn: () => getTags({contexts: contextOrder}),
    refetchOnWindowFocus: false,
    onError: (err) => {
      logger.error(`Failed fetching tags with error ${err}`);
      throw err;
    },
  });

  const classNames = classnames('s-roadmap');

  if (isLoading) {
    return <div className={classNames}></div>;
  }

  tags
    .sort((a, b) => a.indexOrder - b.indexOrder)
    .forEach((tag) => {
      tag.evolutions = evolutions
        .filter((e) => hasFlag(F_OPTION_PORTAL_DISPLAY_ROADMAP, e.optionsFlags))
        .filter((evo) => evo.tags.length > 0 && evo.tags[0].uid === tag.uid);
    });

  const groupedColumns = tags.reduce((acc, cur) => {
    if (acc[cur.context] == null) {
      acc[cur.context] = [];
    }
    acc[cur.context].push(cur);
    return acc;
  }, {});

  return (
    <div className="parent-container">
      {Object.keys(groupedColumns).map((key, groupIndex) => (
        <>
          {groupedColumns[key].map((column, index) => (
            <>
              <Column
                key={column.uid}
                index={index}
                tag={column}
                enablePostCollapse={enablePostCollapse}
                widgetMode={widgetMode}
              />
              {index + 1 < groupedColumns[key].length && (
                <Divider className="column-divider" vertical dark />
              )}
            </>
          ))}
          {groupIndex + 1 < Object.keys(groupedColumns).length && (
            <Divider className="column-divider" vertical dark />
          )}
        </>
      ))}
    </div>
  );
};

const RoadmapWrapper = () => {
  return (
    <div className="roadmap-wrapper">
      <div className={`s-roadmap`}>
        <div className="board-wrapper fade-in-bottom">
          <Roadmap />
        </div>
      </div>
    </div>
  );
};

export default RoadmapWrapper;
