import PropTypes from 'prop-types';
import React from 'react';
import {useState} from 'react';
import {Messenger} from 'managers/messenger';
import Button from 'components/Button';
import {Trans} from 'react-i18next';
import BookingModal from 'components/BookingModal';
import './_Styles.scss';

const propTypes = {
  isLastStep: PropTypes.bool,
  onSurveyEnd: PropTypes.func,
  setStepIndex: PropTypes.func,
  stepIndex: PropTypes.number,
  handleClickBookSlot: PropTypes.func,
};

const defaultProps = {
  isLastStep: false,
  onSurveyEnd: () => {},
  setStepIndex: () => {},
  stepIndex: 0,
  handleClickBookSlot: () => {},
};

const Interview = ({
  isLastStep,
  onSurveyEnd,
  setStepIndex,
  stepIndex,
  boosted,
  onBookSlotClick,
  currentStep,
  widgetMode,
}) => {
  const [bookingLink, setBookingLink] = useState('');

  const handleClickBookSlot = () => {
    if (boosted === true) {
      onBookSlotClick(currentStep.interactiveLink);
      if (isLastStep === true) {
        onSurveyEnd();
      } else {
        setStepIndex(stepIndex + 1);
      }
    } else if (widgetMode === true) {
      Messenger.sendOpenBookingModal(currentStep.interactiveLink);
      if (isLastStep === true) {
        onSurveyEnd();
      } else {
        setStepIndex(stepIndex + 1);
      }
    } else {
      setBookingLink(currentStep.interactiveLink);
    }
  };

  return (
    <>
      <div className="interview-wrapper">
        <Button
          className="not-now-btn"
          onClick={() => {
            if (isLastStep) {
              return onSurveyEnd();
            } else {
              setStepIndex(stepIndex + 1);
            }
          }}>
          <Trans i18nKey="others.surveyBookingNotNow" />
        </Button>
        <Button
          primary
          style={{
            backgroundColor:
              document.documentElement.style.getPropertyValue(
                '--boostedSecondaryColor'
              ) || '#409af8',
          }}
          className="book-slot-btn"
          onClick={handleClickBookSlot}>
          <Trans i18nKey="others.surveyBookingBookSlot" />
        </Button>
      </div>
      <BookingModal
        link={bookingLink}
        isOpen={!!bookingLink}
        onRequestClose={() => {
          setBookingLink('');
          if (isLastStep === true) {
            onSurveyEnd();
          } else {
            setStepIndex(stepIndex + 1);
          }
        }}
      />
    </>
  );
};

Interview.propTypes = propTypes;
Interview.defaultProps = defaultProps;

export default Interview;
