import Divider from 'components/Divider';
import {VOTE_IDK, VOTE_NAH, VOTE_YEAH} from 'components/VoteNps';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans} from 'react-i18next';
import {EmojiIdk, EmojiNah, EmojiYeah} from 'shared/front/components/Emojis';
import './_Styles.scss';

const propTypes = {
  handleSubmit: PropTypes.func,
  value: PropTypes.string,
};

const defaultProps = {
  handleSubmit: () => {},
  value: '',
};

const OpinionScale = ({handleSubmit, value}) => {
  return (
    <div className="opinion-scale-wrapper">
      <Divider dark />
      <div className="opinion-wrapper">
        <div className="opinion-scale">
          <EmojiNah
            animated
            animateOnHover
            disabled={value !== VOTE_NAH}
            tooltip={<Trans i18nKey="others.npsLabelNah" />}
            tooltipProps={{on: ['hover', 'focus']}}
            onClick={() => handleSubmit(VOTE_NAH)}
          />
          <EmojiIdk
            animated
            animateOnHover
            disabled={value !== VOTE_IDK}
            tooltip={<Trans i18nKey="others.npsLabelIdk" />}
            tooltipProps={{on: ['hover', 'focus']}}
            onClick={() => handleSubmit(VOTE_IDK)}
          />
          <EmojiYeah
            width={48}
            animated
            animateOnHover
            disabled={value !== VOTE_YEAH}
            tooltip={<Trans i18nKey="others.npsLabelYeah" />}
            tooltipProps={{on: ['hover', 'focus']}}
            onClick={() => handleSubmit(VOTE_YEAH)}
          />
        </div>
      </div>
    </div>
  );
};

OpinionScale.propTypes = propTypes;
OpinionScale.defaultProps = defaultProps;

export default OpinionScale;
