import {generalActions} from 'actions';
import {hasFlag} from 'helpers/bitwise';
import queryString from 'query-string';
import React, {useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, useLocation} from 'react-router-dom';
import {
  ROUTE_INTERCOM_EVOLUTION_ID,
  ROUTE_INTERCOM_FEED,
  ROUTE_INTERCOM_FEEDBACK,
  ROUTE_INTERCOM_ROADMAP,
  ROUTE_WIDGET_EVOLUTION_ID,
  ROUTE_WIDGET_FEED,
  ROUTE_WIDGET_FEEDBACK,
  ROUTE_WIDGET_ROADMAP,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {analyticService} from 'services';
import {EventContext} from 'services/analytic';
import {F_PAGE_FEED, F_PAGE_FEEDBACK, F_PAGE_ROADMAP} from 'services/project';
import {Swaler} from 'swaler';
import './_Styles.scss';

const WidgetEnter = (props) => {
  const logger = new Swaler('Stormwind/WidgetEnter');

  const location = useLocation();
  const dispatch = useDispatch();

  const project = useSelector((state) => generalSelector.getProject(state));

  const setWidgetMode = (widgetMode) =>
    dispatch(generalActions.setWidgetMode(widgetMode));

  useEffect(() => {
    const setup = async () => {
      const {b: boosted, eId: evolutionId} = queryString.parse(location.search);

      setWidgetMode(true);
      if (boosted === 'true' && evolutionId != null) {
        analyticService.createClick({
          evolutionId,
          context: EventContext.IN_APP,
        });
      }
    };

    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    gt: goTo,
    eId: evolutionId,
    v: vote,
    sf: startFrom,
  } = queryString.parse(location.search);
  const {isIntercom} = props;

  if (goTo != null) {
    if (goTo === 'feedback') {
      return (
        <Redirect
          to={
            isIntercom === true
              ? ROUTE_INTERCOM_FEEDBACK
              : ROUTE_WIDGET_FEEDBACK
          }
        />
      );
    } else if (goTo === 'evolution') {
      return (
        <Redirect
          to={{
            pathname:
              isIntercom === true
                ? ROUTE_INTERCOM_EVOLUTION_ID(evolutionId)
                : ROUTE_WIDGET_EVOLUTION_ID(evolutionId),

            state: {vote, startFrom},
          }}
        />
      );
    }
  }

  const isFeedActive = hasFlag(F_PAGE_FEED, project.widgetContentFlags);
  const isRoadmapActive = hasFlag(F_PAGE_ROADMAP, project.widgetContentFlags);
  const isFeedbackActive = hasFlag(F_PAGE_FEEDBACK, project.widgetContentFlags);

  const sectionsStr = project.widgetSectionOrder || 'feed;roadmap;feedback';
  const sections = sectionsStr.split(';').filter((s) => {
    if (isFeedActive !== true && s === 'feed') {
      return false;
    }
    if (isRoadmapActive !== true && s === 'roadmap') {
      return false;
    }
    if (isFeedbackActive !== true && s === 'feedback') {
      return false;
    }
    return true;
  });
  const firstSection = sections[0];

  if (firstSection === 'feedback') {
    return (
      <Redirect
        to={
          isIntercom === true ? ROUTE_INTERCOM_FEEDBACK : ROUTE_WIDGET_FEEDBACK
        }
      />
    );
  } else if (firstSection === 'roadmap') {
    return (
      <Redirect
        to={isIntercom === true ? ROUTE_INTERCOM_ROADMAP : ROUTE_WIDGET_ROADMAP}
      />
    );
  } else {
    return (
      <Redirect
        to={isIntercom === true ? ROUTE_INTERCOM_FEED : ROUTE_WIDGET_FEED}
      />
    );
  }
};

export default withTranslation()(WidgetEnter);
