import {generalActions} from 'actions';
import classnames from 'classnames';
import CardPush from 'components/CardPush';
import Loader from 'components/Loader';
import {toastDanger} from 'components/Toaster';
import queryString from 'query-string';
import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {generalSelector} from 'selectors';
import {analyticService, evolutionService} from 'services';
import {EventContext} from 'services/analytic';
import {Swaler} from 'swaler';
import './_styles.scss';
import PostDetails from './components/PostDetails';

const Evolution = ({uptEvolutionById}) => {
  const logger = new Swaler('Evolution');

  const location = useLocation();

  const evolutions = useSelector((state) =>
    generalSelector.getEvolutions(state)
  );
  const project = useSelector((state) => generalSelector.getProject(state));

  const {evolutionId} = useParams();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const {from_intercom_messenger} = queryString.parse(location.search);

    if (from_intercom_messenger === 'true') {
      analyticService.createClick({
        evolutionId,
        context: EventContext.PORTAL_WIDGET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchEvolution = async () => {
      try {
        const evolution = await evolutionService.getEvolutionById(evolutionId, {
          relations: ['comments', 'comments.likes'],
        });

        uptEvolutionById(evolutionId, evolution);
      } catch (err) {
        logger.error('Fetching evolution failed with err', err);
        return toastDanger([
          'Wups...',
          'We could not fetch this evolution, please try again later!',
        ]);
      }
    };

    const setup = async () => {
      await fetchEvolution();
      setIsLoading(false);
    };

    setup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEvolution = () => evolutions.find((e) => e.uid === evolutionId);

  const evolution = getEvolution();
  const classNames = classnames('evolution-wrapper', {
    'no-padding-bottom': project.whiteLabeling === true,
    'is-loading': isLoading,
  });

  if (isLoading === true) {
    return (
      <div className={classNames}>
        <Loader width="24px" />
      </div>
    );
  }

  const isOldVersionPost = !(evolution?.steps?.[0]?.blocks?.length > 0);

  return (
    <div className={classNames}>
      {isOldVersionPost === true ? (
        <CardPush
          evolution={evolution}
          withComments
          widgetMode
          startFrom={{uid: location.state?.startFrom}}
        />
      ) : (
        <PostDetails post={evolution} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  uptEvolutionById: (evolutionId, evolution) =>
    dispatch(generalActions.uptEvolutionById(evolutionId, evolution)),
});

export default connect(null, mapDispatchToProps)(Evolution);
