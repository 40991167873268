import Button from 'components/Button';
import {hasFlag} from 'helpers/bitwise';
import i18n from 'i18next';
import {Messenger} from 'managers/messenger';
import {bool, func, object, string} from 'prop-types';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {analyticService} from 'services';
import {EventContext} from 'services/analytic';
import {F_STEP_CTA_IN_NEW_TAB} from 'services/steps';
import './_Styles.scss';

const propTypes = {
  evolutionId: string,
  step: object.isRequired,
  onCtaClick: func,
  boosted: bool,
  useCustomColor: bool,
  onClosePoke: func,
  widgetMode: bool,
  buttonStyle: object,
};

const defaultProps = {
  onCtaClick: () => {},
  boosted: false,
  useCustomColor: false,
  onClosePoke: () => {},
  widgetMode: false,
  buttonStyle: {},
};

const CTA_ACTION_CLOSE_POKE = 'CLOSE_POKE';

const Cta = ({
  evolutionId,
  step,
  onCtaClick,
  boosted,
  onClosePoke,
  widgetMode,
  buttonStyle,
}) => {
  const {
    ctaBorderRadius,
    ctaBackgroundColor,
    ctaColor,
    typeformFormId,
    ctaAction,
    ctaFontSize,
  } = step;

  const isWidgetMode = useSelector((state) =>
    generalSelector.getWidgetMode(state)
  );

  const [isLoading, setIsLoading] = useState(false);

  const text = step?.texts?.find(
    (t) => t.language === i18n.language.toUpperCase()
  );
  const ctaUrl = (text != null && text.ctaUrl) || step.ctaUrl;
  const ctaLabel = (text != null && text.ctaLabel) || step.ctaLabel;

  const context =
    boosted === true
      ? EventContext.IN_APP
      : widgetMode === true
      ? EventContext.PORTAL_WIDGET
      : EventContext.PORTAL_STANDALONE;

  const handleClick = async (e) => {
    if (ctaAction === CTA_ACTION_CLOSE_POKE) {
      return onClosePoke();
    }
    setIsLoading(true);
    await analyticService.createCtaClick({
      // add step here when we will start tracking analytics at step level
      evolutionId,
      context,
    });
    if (hasFlag(F_STEP_CTA_IN_NEW_TAB, step.stepFlags) === false) {
      e.preventDefault();
      e.stopPropagation();

      if (isWidgetMode || boosted === true) {
        onCtaClick();
        setIsLoading(false);
        return Messenger.sendOpenCtaUrl(ctaUrl);
      }
      window.location = ctaUrl;
    }
    setIsLoading(false);
    return onCtaClick();
  };

  if (ctaUrl != null) {
    const shouldUseHandler =
      ctaAction !== CTA_ACTION_CLOSE_POKE &&
      hasFlag(F_STEP_CTA_IN_NEW_TAB, step.stepFlags) === false;

    return (
      <div className="cta-wrapper">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href={shouldUseHandler ? '#' : ctaUrl}
          target={
            ctaAction !== CTA_ACTION_CLOSE_POKE &&
            hasFlag(F_STEP_CTA_IN_NEW_TAB, step.stepFlags)
              ? '_blank'
              : null
          }
          rel="noopener noreferrer"
          onClick={handleClick}>
          <Button
            style={{
              color: ctaColor,
              backgroundColor: ctaBackgroundColor,
              borderRadius: `${ctaBorderRadius}px`,
              fontSize: `${ctaFontSize}px`,
              ...buttonStyle,
            }}
            loading={isLoading}>
            {ctaLabel}
          </Button>
        </a>
      </div>
    );
  }

  return <></>;
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
