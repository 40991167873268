import React from 'react';
import PropTypes from 'prop-types';
import './_Styles.scss';

const Content = ({evolution, content}) => {
  return (
    <div
      key={evolution.boostedContentFontSize}
      className="step-additional-content"
      style={{
        color:
          document.documentElement.style.getPropertyValue(
            '--boostedColorContent'
          ) || '#071331',
        fontSize:
          document.documentElement.style.getPropertyValue(
            '--boostedContentFontSize'
          ) || '14px',
      }}>
      <div className="md-wrapper">{content}</div>
    </div>
  );
};

Content.propTypes = {
  evolution: PropTypes.shape({
    boostedContentFontSize: PropTypes.string,
  }).isRequired,
  content: PropTypes.string.isRequired,
};

Content.defaultProps = {};

export default Content;
