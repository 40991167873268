import {generalActions} from 'actions';
import classnames from 'classnames';
import AnalyticViewTrigger from 'components/AnalyticViewTrigger';
import Button from 'components/Button';
import CardPush from 'components/CardPush';
import Cta from 'components/Cta';
import BasicFooter from 'components/Footer';
import Loader from 'components/Loader';
import TagButton from 'components/TagButton';
import i18n from 'conf/i18n';
import colorContrast from 'font-color-contrast';
import {hasFlag, hasFlags} from 'helpers/bitwise';
import isWindows from 'is-windows';
import {Messenger} from 'managers/messenger';
import React, {useEffect, useRef, useState} from 'react';
import {Col, Row} from 'react-flexbox-grid';
import {Helmet} from 'react-helmet-async';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import PreviewPrototype from 'scenes/Preview/components/Prototype';
import PreviewSidebar from 'scenes/Preview/components/Sidebar';
import {generalSelector} from 'selectors';
import {evolutionService} from 'services';
import {EventContext, EventType, ViewType} from 'services/analytic';
import {
  F_OPTION_DOT_SHOW_ON_HOVER,
  F_OPTION_PORTAL_DISPLAY_FEED,
  F_OPTION_PORTAL_DISPLAY_FEEDBACK,
  F_OPTION_PORTAL_DISPLAY_ROADMAP,
} from 'services/evolution';
import {STEP_TYPE_TEXT_BLOCK} from 'services/steps';
import {Swaler} from 'swaler';
import './_Styles.scss';

export const F_SLOT_TOP_BAR = 1;
export const F_SLOT_POP_IN = 2;
export const F_SLOT_SNIPPET = 4;
export const F_SLOT_DOT = 8;
export const F_SLOT_TOOLTIP = 32;
export const WIDGET_POSITION_BOTTOM_LEFT = 'BOTTOM_LEFT';

export const hasSomeParentTheClass = (element, classname) => {
  if (element.classList?.contains(classname)) return true;
  return (
    element.parentNode && hasSomeParentTheClass(element.parentNode, classname)
  );
};

const logger = new Swaler('Boosted');

const defaultPrimaryColor = '#FFFFFF';
const defaultSecondaryColor = '#000000';
const defaultRoundness = 8;
const defaultTitleFontSize = 20;
const defaultContentFontSize = 14;

export const REQUEST_POKE = 'REQUEST_POKE';
export const SEND_POKE = 'SEND_POKE';
export const SEND_VISIBILITY = 'SEND_VISIBILITY';
export const SEND_CURRENT_URL = 'SEND_CURRENT_URL';
export const CURRENT_URL_REQUEST = 'CURRENT_URL_REQUEST';
export const SEND_DISMISS = 'SEND_DISMISS';
export const SEND_SIZE = 'SEND_SIZE';
const STEP_ID_SELECTED = 'STEP_ID_SELECTED';

const Boosted = () => {
  const {evolutionId} = useParams();

  const dispatch = useDispatch();

  const project = useSelector((state) => generalSelector.getProject(state));

  const [manualSize, setManualSize] = useState(false);
  const [windowWidth, setWindowWidth] = useState(800);
  const [windowHeight, setWindowHeight] = useState(0);
  const [previewMode, setPreviewMode] = useState(false);
  const [previewStep, setPreviewStep] = useState(null);
  const [evolution, setEvolution] = useState(null);
  const [popInHeight, setPopInHeight] = useState(null);
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [shouldSaveDismissEvent, setShouldSaveDismissEvent] = useState(false);

  const [builderSelectedStepId, setBuilderSelectedStepId] = useState(null);

  const refPopInContainer = useRef(null);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const isBoostedPreview = params.get('boosted_preview') === 'true';
  const isInteractivePreview = params.get('interactive_preview') === 'true';
  const isForcedHotspot = params.get('force_hotspot') === 'true';

  const parentIFrame = window.parentIFrame;

  useEffect(() => {
    if ('parentIFrame' in window && manualSize !== true) {
      window.parentIFrame.getPageInfo(
        ({windowWidth, windowHeight, ...rest}) => {
          setWindowWidth(windowWidth);
          setWindowHeight(windowHeight);
        }
      );
    }
  }, [parentIFrame, manualSize]);

  useEffect(() => {
    const setup = async () => {
      window.parentIFrame.sendMessage(
        JSON.stringify({
          action: CURRENT_URL_REQUEST,
          evolutionId,
          fromJimo: true,
        })
      );

      const handleParentMessage = (message) => {
        try {
          const iframeResizerIdentifier = `[iFrameSizer]message:`;
          if (
            message.data.slice(0, iframeResizerIdentifier.length) ===
            iframeResizerIdentifier
          ) {
            const formattedMessage = JSON.parse(
              message.data.substring(iframeResizerIdentifier.length)
            );

            if (formattedMessage?.action === SEND_VISIBILITY) {
              setIsIframeVisible(formattedMessage.visible);
            }
            if (formattedMessage?.action === SEND_CURRENT_URL) {
              dispatch(generalActions.setCurrentUrl(formattedMessage.url));
            }
            if (formattedMessage?.action === SEND_DISMISS) {
              setShouldSaveDismissEvent(true);
            }
          }
        } catch (err) {}
      };

      window.addEventListener('message', handleParentMessage);

      try {
        const evolution = await evolutionService.getEvolutionById(evolutionId, {
          relations: [
            'myVote',
            'mySurvey',
            'texts',
            'tags',
            'steps',
            'steps.thumbnails',
          ],
          ...(isBoostedPreview ? {isPreview: true} : {}),
        });
        // eslint-disable-next-line no-unused-vars
        const [_, stepsBefore, stepsAfter] = (
          evolution.tourStepInfo || '0;0;0'
        ).split(';');
        setEvolution({
          ...evolution,
          stepsBefore: parseInt(stepsBefore, 10),
          stepsAfter: parseInt(stepsAfter, 10),
        });

        setIsLoaded(true);
      } catch (err) {
        if (err.response?.data.message === 'ACCESS_EVOLUTION_DENIED') {
          Messenger.sendForbiddenBoosted(evolutionId);
        }
        logger.error('Fetching evolution failed with err', err);
      }

      return () => {
        window.removeEventListener('message', handleParentMessage);
      };
    };

    const setupPreview = () => {
      window.parentIFrame.sendMessage(
        JSON.stringify({action: REQUEST_POKE, evolutionId, fromJimo: true})
      );

      const handlePreviewBoostedReceived = (message) => {
        try {
          const iframeResizerIdentifier = `[iFrameSizer]message:`;
          if (
            message.data.slice(0, iframeResizerIdentifier.length) ===
            iframeResizerIdentifier
          ) {
            const formattedMessage = JSON.parse(
              message.data.substring(iframeResizerIdentifier.length)
            );

            if (formattedMessage?.action === SEND_POKE) {
              setEvolution(formattedMessage.evolution);
              if (formattedMessage.height && formattedMessage.width) {
                setWindowWidth(formattedMessage.width);
                setWindowHeight(formattedMessage.height);
                setManualSize(true);
              }
              setIsLoaded(true);
            }
            if (formattedMessage?.action === STEP_ID_SELECTED) {
              setBuilderSelectedStepId(formattedMessage.stepId);
            }
            if (formattedMessage?.action === SEND_SIZE) {
              setWindowWidth(formattedMessage.width);
              setWindowHeight(formattedMessage.height);
              setManualSize(true);
            }
          }
        } catch (err) {}
      };

      window.addEventListener('message', handlePreviewBoostedReceived);

      return () => {
        window.removeEventListener('message', handlePreviewBoostedReceived);
      };
    };

    // use interval here because couldn't manage to make onReady option work (see: https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/iframed_page/events.md)
    const interval = setInterval(() => {
      if (window.parentIFrame != null) {
        if (isBoostedPreview) {
          setupPreview();
        } else {
          setup();
        }
        clearInterval(interval);
      }
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const conceptTestStep = evolution?.steps.find((s) =>
      s.prototypes?.[0]?.steps
        ?.map((s) => s.uid)
        .includes(builderSelectedStepId)
    );
    const isPreviewStep = conceptTestStep != null;
    if (builderSelectedStepId && isPreviewStep === true) {
      togglePreview(conceptTestStep);
    } else if (previewMode === true) {
      unTogglePreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evolution?.steps, builderSelectedStepId]);

  const setIsLoaded = () => {
    if ('parentIFrame' in window) {
      window.parentIFrame.sendMessage(
        JSON.stringify({action: 'loaded', evolutionId, fromJimo: true})
      );
    }
  };

  if (evolution == null) {
    return <></>;
  }

  // @TODO Use Messenger
  const handleClose = () => {
    if ('parentIFrame' in window) {
      window.parentIFrame.sendMessage(
        JSON.stringify({action: 'close', evolutionId, fromJimo: true})
      );
    }
  };

  const handleDismiss = () => {
    if ('parentIFrame' in window) {
      window.parentIFrame.sendMessage(
        JSON.stringify({action: 'skip', evolutionId, fromJimo: true})
      );
    }
  };

  const openEvolutionInWidget = () => {
    if ('parentIFrame' in window) {
      window.parentIFrame.sendMessage(
        JSON.stringify({action: 'open-widget', evolutionId, fromJimo: true})
      );
    }
  };
  // Keep it here just in case
  const showImage = (src) => {
    if ('parentIFrame' in window) {
      window.parentIFrame.sendMessage(
        JSON.stringify({action: 'show-image', src, evolutionId, fromJimo: true})
      );
    }
  };
  const togglePreview = async (step) => {
    const isFigmaOrInvision = ['invis.io', 'invisionapp.com', 'figma.com'].some(
      (domain) => step.interactiveLink.includes(domain)
    );
    if (
      isFigmaOrInvision === false &&
      isBoostedPreview !== true &&
      step.interactiveLink
    ) {
      return window.open(step.interactiveLink, '_blank');
    }
    if ('parentIFrame' in window) {
      window.parentIFrame.sendMessage(
        JSON.stringify({
          action: 'toggle-preview',
          value: true,
          evolutionId,
          fromJimo: true,
        })
      );
    }
    setPreviewStep(step);
    setPreviewMode(true);
    Messenger.sendFullscreenView(true);
  };
  const toggleBooking = async (link) => {
    if ('parentIFrame' in window) {
      window.parentIFrame.sendMessage(
        JSON.stringify({
          action: 'toggle-booking',
          value: true,
          link,
          fromJimo: true,
        })
      );
    }
  };
  const unTogglePreview = async () => {
    Messenger.sendFullscreenView(false);
    if ('parentIFrame' in window) {
      window.parentIFrame.sendMessage(
        JSON.stringify({
          action: 'toggle-preview',
          value: false,
          evolutionId,
          fromJimo: true,
        })
      );
    }
    setPreviewMode(false);
  };

  const classNames = classnames('boosted', {
    'top-bar': hasFlag(F_SLOT_TOP_BAR, evolution.boostFlags),
    snippet:
      hasFlag(F_SLOT_SNIPPET, evolution.boostFlags) ||
      hasFlag(F_SLOT_TOOLTIP, evolution.boostFlags) ||
      (hasFlag(F_SLOT_DOT, evolution.boostFlags) &&
        isBoostedPreview &&
        isInteractivePreview !== true &&
        isForcedHotspot !== true),
    'pop-in': hasFlag(F_SLOT_POP_IN, evolution.boostFlags),
    'is-windows': isWindows() === true,
  });
  const textColor = colorContrast(project.widgetThemeColor);
  const tag = evolution?.tags?.[0];
  const [boostedColorTitle, boostedColorContent] =
    evolution.boostedTextsColors.split(';');
  const [boostedDotColor, boostedDotSize] =
    evolution.boostedDotStyle.split(';');

  document.documentElement.style.setProperty(
    '--boostedPrimaryColor',
    evolution.boostedPrimaryColor || defaultPrimaryColor
  );
  document.documentElement.style.setProperty(
    '--boostedSecondaryColor',
    evolution.boostedSecondaryColor || defaultSecondaryColor
  );
  document.documentElement.style.setProperty(
    '--boostedRoundness',
    `${
      evolution.boostedRoundness || evolution.boostedRoundness === 0
        ? evolution.boostedRoundness
        : defaultRoundness
    }px`
  );
  document.documentElement.style.setProperty(
    '--boostedTitleFontSize',
    `${
      evolution.boostedTitleFontSize
        ? evolution.boostedTitleFontSize
        : defaultTitleFontSize
    }px`
  );
  document.documentElement.style.setProperty(
    '--boostedContentFontSize',
    `${
      evolution.boostedContentFontSize
        ? evolution.boostedContentFontSize
        : defaultContentFontSize
    }px`
  );
  document.documentElement.style.setProperty(
    '--boostedColorTitle',
    `${boostedColorTitle}`
  );
  document.documentElement.style.setProperty(
    '--boostedColorContent',
    `${boostedColorContent}`
  );
  document.documentElement.style.setProperty(
    '--boostedSurveyChoicesColor',
    colorContrast(evolution.boostedPrimaryColor)
  );
  document.documentElement.style.setProperty(
    '--boostedSurveySelectDotColor',
    colorContrast(evolution.boostedSecondaryColor)
  );

  if (previewMode === true) {
    let previewContent = <></>;
    const style = {};
    style.width = windowWidth;
    style.height = windowHeight;
    style.maxHeight = '100%';
    style.backgroundColor = 'transparent';

    if (previewStep == null) {
      previewContent = (
        <div className="preview-loading">
          <Loader width="18px" /> Preview is loading...
        </div>
      );
    } else {
      previewContent = (
        <Row>
          <Col xs={12} className="top-col">
            <div className="btn-close-wrapper">
              <Button
                iconLeft="icon-chevron-left"
                light
                rounded={false}
                onClick={() => {
                  unTogglePreview(false);
                }}>
                Close
              </Button>
            </div>
          </Col>
          <Col xs={12} className="preview-main-wrapper">
            <Col xs={12} sm={8} xl={9}>
              <PreviewPrototype widgetMode={true} step={previewStep} />
            </Col>
            <Col xs={12} sm={4} xl={3}>
              <PreviewSidebar
                evolution={evolution}
                step={previewStep}
                onSurveyEnd={(updatedEvolution) => {
                  if (isBoostedPreview === true) {
                    return unTogglePreview();
                  }
                  // prevent overwriting boostFlags after hotspot have been turned to snippet
                  delete updatedEvolution.boostFlags;

                  setEvolution((prevState) => ({
                    ...prevState,
                    ...updatedEvolution,
                  }));
                }}
                isBoostedPreview={isBoostedPreview}
                builderSelectedStepId={builderSelectedStepId}
              />
            </Col>
          </Col>
        </Row>
      );
    }

    return (
      <div
        className={classnames(classNames, 'fade-in-bottom', {
          preview: previewMode === true,
        })}
        style={style}>
        <Helmet>
          <html className="html-boosted" />
          <body
            className={classnames('boosted-mode', {
              'light preview-widget-bg': previewMode === true,
            })}
          />
        </Helmet>
        <div className="s-preview is-widget-mode">{previewContent}</div>
      </div>
    );
  }

  // Top bar
  if (hasFlag(F_SLOT_TOP_BAR, evolution.boostFlags)) {
    const brightness =
      colorContrast(project.widgetThemeColor) === '#ffffff' ? 1 : 0;
    const firstStep = evolution.steps[0];
    const title =
      firstStep?.texts?.find((t) => t.language === i18n.language.toUpperCase())
        ?.question || firstStep?.question;
    const isClickable = hasFlags(
      [
        F_OPTION_PORTAL_DISPLAY_FEED,
        F_OPTION_PORTAL_DISPLAY_FEEDBACK,
        F_OPTION_PORTAL_DISPLAY_ROADMAP,
      ],
      evolution.optionsFlags,
      true
    );

    return (
      <div
        className={classnames(classNames, 'fade-in-top', {
          'is-clickable': isClickable,
        })}
        style={{color: textColor}}
        onClick={(e) => {
          if (
            !hasSomeParentTheClass(e.target, 'cta-wrapper') &&
            !hasSomeParentTheClass(e.target, 'close-wrapper') &&
            isClickable === true
          ) {
            openEvolutionInWidget();
            handleClose();
          }
        }}>
        <Helmet>
          <html className="html-boosted" />
          <body className="boosted-mode" />
        </Helmet>
        {tag != null && (
          <div className="tag-wrapper">
            <TagButton tag={tag} />
          </div>
        )}
        <span className="title">{title}</span>
        {firstStep?.type === STEP_TYPE_TEXT_BLOCK && (
          <Cta
            evolutionId={evolution.uid}
            step={firstStep}
            boosted
            onCtaClick={() => {
              handleClose();
            }}
          />
        )}
        <div
          className="close-wrapper"
          style={{filter: `brightness(${brightness})`}}
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}>
          <i className="icon-close" />
        </div>
        {isBoostedPreview !== true && isIframeVisible && (
          <AnalyticViewTrigger
            evolution={evolution}
            context={EventContext.IN_APP}
            type={EventType.EVOLUTION_SEEN}
          />
        )}
      </div>
    );
  }
  // Pop in
  if (hasFlag(F_SLOT_POP_IN, evolution.boostFlags)) {
    const style = {};
    let multiplicator = 40 / 100;

    if (windowWidth < 1200 && windowWidth > 600) {
      multiplicator = 80 / 100;
    } else if (windowWidth <= 600) {
      multiplicator = 95 / 100;
    }

    style.maxWidth = `${multiplicator * windowWidth}px`;
    style.width = style.maxWidth;
    style.backgroundColor = evolution.boostedPrimaryColor;
    const height = (60 / 100) * windowHeight;
    style.maxHeight = height;

    if (parseInt(popInHeight, 10) >= parseInt(height, 10)) {
      style.height = `${height}px`;
    }

    return (
      <div
        className={classnames(classNames, 'fade-in-bottom')}
        style={{
          ...style,
        }}
        ref={refPopInContainer}>
        <div className="pop-in-wrapper">
          <Helmet>
            <html className="html-boosted" />
            <body className="boosted-mode boosted-pop-in" />
          </Helmet>
          <CardPush
            className="fade-in"
            evolution={evolution}
            boosted
            onConceptTestClick={togglePreview}
            onBookSlotClick={toggleBooking}
            startFrom={previewStep}
            isBoostedPreview={isBoostedPreview}
            builderSelectedStepId={builderSelectedStepId}
            onStepChange={() => {
              if (refPopInContainer.current != null && popInHeight < height) {
                setPopInHeight(refPopInContainer.current.clientHeight);
              } else {
                setPopInHeight(null);
              }
            }}
            onDismiss={handleDismiss}
            onClose={handleClose}
            isIframeVisible={isIframeVisible}
            shouldSaveDismissEvent={shouldSaveDismissEvent}
            onImageClick={showImage}
          />
          <BasicFooter isLight />
        </div>
      </div>
    );
  }
  // Snippet
  if (
    hasFlag(F_SLOT_SNIPPET, evolution.boostFlags) ||
    hasFlag(F_SLOT_TOOLTIP, evolution.boostFlags) ||
    (hasFlag(F_SLOT_DOT, evolution.boostFlags) &&
      isBoostedPreview &&
      isInteractivePreview !== true &&
      isForcedHotspot !== true)
  ) {
    return (
      <div
        className={classnames(classNames)}
        style={{
          ...(evolution.boostedSize != null
            ? {
                width: `${evolution.boostedSize}px`,
              }
            : {}),
        }}>
        <Helmet>
          <html className="html-boosted" />
          <body className="boosted-mode" />
        </Helmet>

        <CardPush
          className="fade-in"
          evolution={evolution}
          boosted
          onConceptTestClick={togglePreview}
          onBookSlotClick={toggleBooking}
          startFrom={previewStep}
          isBoostedPreview={isBoostedPreview}
          builderSelectedStepId={builderSelectedStepId}
          onDismiss={handleDismiss}
          onClose={handleClose}
          isIframeVisible={isIframeVisible}
          onImageClick={showImage}
        />
        <BasicFooter isLight />
      </div>
    );
  }
  if (hasFlag(F_SLOT_DOT, evolution.boostFlags)) {
    return (
      <div
        className="dot-wrapper"
        style={{
          width: `${parseInt(boostedDotSize, 10) + 30}px`,
          height: `${parseInt(boostedDotSize, 10) + 30}px`,
        }}
        onClick={() => {
          if (
            hasFlag(F_OPTION_DOT_SHOW_ON_HOVER, evolution.optionsFlags) !== true
          ) {
            setEvolution({
              ...evolution,
              boostFlags: F_SLOT_SNIPPET,
            });
            window.parentIFrame.sendMessage(
              JSON.stringify({
                action: 'dot-to-snippet',
                evolutionId,
                fromJimo: true,
              })
            );
          }
        }}
        onMouseEnter={() => {
          if (
            hasFlag(F_OPTION_DOT_SHOW_ON_HOVER, evolution.optionsFlags) === true
          ) {
            setEvolution({
              ...evolution,
              boostFlags: F_SLOT_SNIPPET,
            });
            window.parentIFrame.sendMessage(
              JSON.stringify({
                action: 'dot-to-snippet',
                evolutionId,
                fromJimo: true,
              })
            );
          }
        }}>
        <Helmet>
          <html className="html-boosted" />
          <body className="boosted-mode" />
        </Helmet>
        <div
          className="pulsating-circle"
          style={{
            width: `${boostedDotSize}px`,
            height: `${boostedDotSize}px`,
            backgroundColor: boostedDotColor,
            color: boostedDotColor,
          }}
        />
      </div>
    );
  }
  return <></>;
};

export default Boosted;
