import TagButton from 'components/TagButton';
import React from 'react';
import EvolutionList from '../EvolutionList';
import './_Styles.scss';

const Column = ({tag, enablePostCollapse, widgetMode}) => (
  <>
    <div className="column-wrapper">
      <div className="column">
        <div className="header">
          <div className="pipe-header">
            <div className="column-name">
              <TagButton tag={tag} interactive={false} />
            </div>
          </div>
        </div>
        <EvolutionList
          listId={tag.uid}
          listType="EVOLUTION"
          evolutions={tag.evolutions}
          context={tag.context}
          enablePostCollapse={enablePostCollapse}
          widgetMode={widgetMode}
        />
      </div>
    </div>
  </>
);

export default Column;
