import {AppContext} from 'App';
import {generalActions} from 'actions';
import classnames from 'classnames';
import AnalyticViewTrigger from 'components/AnalyticViewTrigger';
import Button from 'components/Button';
import Card from 'components/Card';
import EvolutionComments from 'components/EvolutionComments';
import FormComment from 'components/FormComment';
import {toastSuccess} from 'components/Toaster';
import {hasFlag} from 'helpers/bitwise';
import {bool, func, object, string} from 'prop-types';
import React, {useContext, useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {generalSelector} from 'selectors';
import {analyticService, evolutionService} from 'services';
import {EventContext, EventType} from 'services/analytic';
import {F_OPTION_COMMENTS_ENABLED} from 'services/evolution';
import {F_EXTRA_WIDGET_COLLAPSE_POST} from 'services/project';
import './_styles.scss';
import Survey from './components/Survey';

const propTypes = {
  setInnerSurveyHeight: func,
  evolution: object.isRequired,
  hideStats: bool,
  widgetMode: bool,
  isIntercom: bool,
  className: string,
  voteWrapperClassName: string,
  hideHeader: bool,
  hideDate: bool,
  hideDivider: bool,
  enableCompactOnVote: bool,
  focused: bool,
  setFocused: func,
  withComments: bool,
  boosted: bool,
  onStepChange: func,
  onConceptTestClick: func,
  onBookSlotClick: func,
  startFrom: object,
  hideStatus: bool,
  isBoostedPreview: bool,
  enableCollapse: bool,
  showTimestamp: bool,
  builderSelectedStepId: string,
  onDismiss: func,
  onClose: func,
  isIframeVisible: bool,
  shouldSaveDismissEvent: bool,
  onImageClick: func,
  isPreview: bool,
};

const defaultProps = {
  setInnerSurveyHeight: () => {},
  hideStats: false,
  widgetMode: false,
  isIntercom: false,
  className: '',
  voteWrapperClassName: '',
  hideHeader: false,
  hideDate: false,
  hideDivider: false,
  enableCompactOnVote: true,
  focused: false,
  withComments: false,
  boosted: false,
  onStepChange: () => {},
  onConceptTestClick: () => {},
  onBookSlotClick: () => {},
  setFocused: () => {},
  startFrom: null,
  isBoostedPreview: false,
  enableCollapse: false,
  showTimestamp: false,
  builderSelectedStepId: null,
  onDismiss: () => {},
  onClose: () => {},
  isIframeVisible: false,
  shouldSaveDismissEvent: false,
  onImageClick: null,
  isPreview: false,
};

const CardPush = ({
  setInnerSurveyHeight,
  evolution: defaultEvolution,
  hideStats,
  widgetMode,
  isIntercom,
  className,
  voteWrapperClassName,
  hideHeader,
  hideDate,
  hideDivider,
  enableCompactOnVote,
  focused,
  setFocused,
  withComments,
  boosted,
  onStepChange,
  onConceptTestClick,
  onBookSlotClick,
  startFrom,
  hideStatus,
  isBoostedPreview,
  enableCollapse,
  showTimestamp,
  builderSelectedStepId,
  onDismiss,
  onClose,
  isIframeVisible,
  shouldSaveDismissEvent,
  onImageClick,
  isPreview,
  ...rest
}) => {
  const dispatch = useDispatch();

  const {theme} = useContext(AppContext);

  const project = useSelector((state) => generalSelector.getProject(state));
  const storeEvolution = useSelector((state) =>
    generalSelector.getEvolutionById(state, defaultEvolution.uid)
  );
  const evolution = boosted === true ? defaultEvolution : storeEvolution;

  const uptEvolutionById = (data) =>
    dispatch(generalActions.uptEvolutionById(evolution.uid, data));

  const [contentHeight, setContentHeight] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentStepId, setCurrentStepId] = useState(null);
  const [isLastTotalStep, setIsLastTotalStep] = useState(false);

  const context =
    boosted === true
      ? EventContext.IN_APP
      : widgetMode === true
      ? EventContext.PORTAL_WIDGET
      : EventContext.PORTAL_STANDALONE;
  const isTourStep = evolution.parentTourId != null;
  const isFirstTourStep = evolution.stepsBefore === 0;
  const isLastTourStep = evolution.stepsAfter === 0;

  useEffect(() => {
    if (shouldSaveDismissEvent === true) {
      if (isLastTotalStep) {
        if (isTourStep) {
          analyticService.createTourCompleted({
            evolutionId: evolution.parentTourId,
          });
        }
      } else {
        Promise.all([
          analyticService.createDismiss({
            evolutionId: evolution.uid,
          }),
          ...(isTourStep
            ? [
                analyticService.createTourExited({
                  evolutionId: evolution.parentTourId,
                  tourStepId: evolution.uid,
                }),
              ]
            : []),
        ]);
      }
    }
  }, [
    currentStepId,
    evolution.parentTourId,
    evolution.uid,
    isLastTotalStep,
    isTourStep,
    shouldSaveDismissEvent,
  ]);

  const fetchEvolutionWithReponses = async () => {
    const evolutionWithResponses = await evolutionService.getEvolutionById(
      evolution.uid,
      {
        relations: [
          'count.steps.responses',
          'myVote',
          'mySurvey',
          'texts',
          'tags',
          'steps',
          'steps.thumbnails',
        ],
      }
    );

    uptEvolutionById(evolutionWithResponses);
  };

  const handleDismiss = async () => {
    Promise.all([
      analyticService.createDismiss({
        evolutionId: evolution.uid,
      }),
      ...(isTourStep
        ? [
            analyticService.createTourExited({
              evolutionId: evolution.parentTourId,
              tourStepId: evolution.uid,
            }),
          ]
        : []),
    ]);

    onDismiss();
  };

  const handleClose = async () => {
    if (isBoostedPreview !== true) {
      fetchEvolutionWithReponses();

      if (isTourStep && isLastTourStep) {
        analyticService.createTourCompleted({
          evolutionId: evolution.parentTourId,
        });
      }
    }
    onClose();
  };

  const handleSubmitComment = () => {
    toastSuccess([
      <Trans i18nKey="others.commentPostedToastSuccessTitle"></Trans>,
      <Trans i18nKey="others.commentPostedToastSuccessBody"></Trans>,
    ]);
  };

  const shouldCollapse =
    hasFlag(F_EXTRA_WIDGET_COLLAPSE_POST, project.extraFlags) &&
    enableCollapse === true &&
    contentHeight > 340 &&
    isExpanded !== true;
  const classNames = classnames('card-push', className, {
    'widget-mode': widgetMode === true,
    'has-voted': evolution.myVote != null,
    'with-comments': withComments,
    focused: focused === true,
    boosted: boosted === true,
    'is-collapse': focused !== true && shouldCollapse,
  });
  const {typeformFormId} = evolution;
  if (typeformFormId != null) {
    hideDivider = true;
    hideStats = true;
  }

  const hasCommentEnabled = hasFlag(
    F_OPTION_COMMENTS_ENABLED,
    evolution.optionsFlags
  );

  // only use theme if widgetMode is true
  const {cards} = widgetMode === true ? theme ?? {} : {};
  const {backgroundColor, shadow, borderRadius} = cards ?? {};
  const {x, y, blur, color} = shadow ?? {};
  const boxShadow = `${x}px ${y}px ${blur}px ${color}`;

  return (
    <>
      {isPreview !== true &&
        isBoostedPreview !== true &&
        (boosted !== true || isIframeVisible) && (
          <AnalyticViewTrigger
            evolution={evolution}
            context={context}
            type={
              isTourStep === true
                ? EventType.TOUR_STEP_SEEN
                : EventType.EVOLUTION_SEEN
            }
            isFirstTourStep={isFirstTourStep}
            parentTourId={evolution.parentTourId}
          />
        )}
      <div className={classNames}>
        <Card
          widgetMode={widgetMode}
          {...rest}
          style={{
            ...(widgetMode === true
              ? {
                  ...(backgroundColor != null
                    ? {backgroundColor: backgroundColor}
                    : {}),
                  ...(shadow != null
                    ? {
                        boxShadow,
                      }
                    : {}),
                  ...(borderRadius != null
                    ? {
                        borderRadius: `${borderRadius}px`,
                      }
                    : {}),
                }
              : {}),
          }}>
          <div className="content-wrapper">
            <Survey
              setInnerSurveyHeight={(height) => {
                setInnerSurveyHeight(height);
                setContentHeight(height);
              }}
              evolution={evolution}
              steps={evolution.steps || []}
              responses={evolution.mySurvey?.responses ?? []}
              widgetMode={widgetMode}
              onTriggerFocused={setFocused}
              boosted={boosted}
              onSurveyEnd={handleClose}
              onStepChange={onStepChange}
              onConceptTestClick={onConceptTestClick}
              onBookSlotClick={onBookSlotClick}
              startFrom={startFrom}
              hideStatus={hideStatus}
              preview={isPreview || isBoostedPreview}
              showTimestamp={showTimestamp}
              builderSelectedStepId={builderSelectedStepId}
              onDismiss={handleDismiss}
              onClose={handleClose}
              isIframeVisible={isIframeVisible}
              setCurrentStepId={setCurrentStepId}
              setIsLastTotalStep={setIsLastTotalStep}
              onImageClick={onImageClick}
            />
            {withComments === true &&
              (evolution.comments?.length !== 0 ||
                hasCommentEnabled === false) && (
                <div className="comments-wrapper">
                  <EvolutionComments evolution={evolution} />
                </div>
              )}
          </div>
          {withComments === true && hasCommentEnabled === true && (
            <div
              className={classnames('form-comment-wrapper', {
                'evolution-has-no-comments': evolution.comments?.length === 0,
              })}>
              <FormComment
                evolution={evolution}
                onSubmitComment={handleSubmitComment}
              />
            </div>
          )}
          {shouldCollapse && focused !== true && (
            <div className="overlay-read-more">
              <Button
                onClick={(evt) => {
                  evt.stopPropagation();
                  evt.preventDefault();
                  setIsExpanded(true);
                }}
                rounded={false}>
                <Trans i18nKey="common.readMore" />
                <i className="icon-chevron-bottom"></i>
              </Button>
            </div>
          )}
        </Card>
      </div>
      {focused && <div className="focused-overlay" onClick={onClose} />}
    </>
  );
};

CardPush.propTypes = propTypes;
CardPush.defaultProps = defaultProps;

export default CardPush;
