import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {EmojiIdk, EmojiNah, EmojiYeah} from 'shared/front/components/Emojis';
import {string, func} from 'prop-types';
import './_Styles.scss';

const propTypes = {
  value: string.isRequired,
  onChange: func.isRequired,
};

const defaultProps = {};

const CustomSlider = ({value, onChange}) => {
  return (
    <div className="custom-slider">
      <Slider
        min={0}
        max={1000}
        handleRender={({props}) => {
          return (
            <div {...props}>
              {props['aria-valuenow'] > 667 ? (
                <EmojiYeah width={48} animated />
              ) : props['aria-valuenow'] > 334 ? (
                <EmojiIdk width={48} animated />
              ) : (
                <EmojiNah width={48} animated />
              )}
            </div>
          );
        }}
        onChange={onChange}
        value={value || value === 0 ? value : 670}
      />
    </div>
  );
};

CustomSlider.propTypes = propTypes;
CustomSlider.defaultProps = defaultProps;

export default CustomSlider;
