import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '@ramonak/react-progress-bar';

const ProgressBarComponent = ({stepIndex, evolution, totalStepsLength}) => {
  if (totalStepsLength <= 1) {
    return <div className="no-progress-bar"></div>;
  }
  return (
    <ProgressBar
      completed={Math.ceil(
        ((stepIndex + (evolution.stepsBefore || 0) + 1) / totalStepsLength) *
          100
      )}
      className="progress-wrapper"
      barContainerClassName="progress-container"
      bgColor={
        document.documentElement.style.getPropertyValue(
          '--boostedSecondaryColor'
        ) || '#409af8'
      }
      isLabelVisible={false}
    />
  );
};

ProgressBarComponent.propTypes = {
  stepIndex: PropTypes.number.isRequired,
  evolution: PropTypes.shape({
    stepsBefore: PropTypes.number,
  }).isRequired,
  totalStepsLength: PropTypes.number.isRequired,
};

ProgressBarComponent.defaultProps = {};

export default ProgressBarComponent;
