import TagButton from 'components/TagButton';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';

const Status = ({evolution, boosted = false}) => {
  return (
    <div className="tags-timestamp-wrapper">
      <div className="tags-wrapper">
        {evolution?.tags.map((t) => (
          <TagButton tag={t} />
        ))}
      </div>
      {boosted === false && (
        <small
          className="timestamp"
          title={dayjs(evolution?.lastStepChangeAt).format(
            'HH:mm - DD/MM/YYYY'
          )}>
          {dayjs(evolution?.lastStepChangeAt).fromNow()}
        </small>
      )}
    </div>
  );
};

Status.propTypes = {
  shouldShowStatus: PropTypes.bool.isRequired,
  surveyEnded: PropTypes.bool.isRequired,
  evolution: PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    lastStepChangeAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default Status;
