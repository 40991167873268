import TextAreaGroup from 'components/TextAreaGroup';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  setValue: PropTypes.func,
  value: PropTypes.string,
};

const defaultProps = {
  setValue: () => {},
  value: '',
};

const OpenQuestion = ({setValue, value}) => {
  return (
    <>
      <div className="input-wrapper">
        <TextAreaGroup
          className="text-long-textarea"
          style={{resize: 'none'}}
          placeholder="Type your answer here"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    </>
  );
};

OpenQuestion.propTypes = propTypes;
OpenQuestion.defaultProps = defaultProps;

export default OpenQuestion;
