import PropTypes from 'prop-types';
import React from 'react';
import CustomSlider from '../../components/CustomSlider';

const propTypes = {
  setValue: PropTypes.func,
  value: PropTypes.string,
};

const defaultProps = {
  setValue: () => {},
  value: '',
};

const Slider = ({setValue, value}) => {
  return (
    <>
      <div className="slider-wrapper">
        <CustomSlider value={value} onChange={setValue} />
      </div>
    </>
  );
};

Slider.propTypes = propTypes;
Slider.defaultProps = defaultProps;

export default Slider;
