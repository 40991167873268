import classnames from 'classnames';
import i18n from 'conf/i18n';
import React from 'react';
import IframeFigma from './iframe-figma';
import IframeInvision from './iframe-invision';
import './_Styles.scss';

const PreviewPrototype = ({step}) => {
  const interactiveLink =
    step.texts.find((t) => t.language === i18n.language.toUpperCase())
      ?.interactiveLink || step.interactiveLink;
  const isInvisionInteractivePreview = ['invis.io', 'invisionapp.com'].some(
    (domain) => interactiveLink.includes(domain)
  );
  const isFigmaInteractivePreview = interactiveLink.includes('figma.com');

  return (
    <div className="preview—prototype fade-in-right">
      <div className={classnames('center-panel')}>
        {isInvisionInteractivePreview && (
          <IframeInvision link={interactiveLink} />
        )}
        {isFigmaInteractivePreview && <IframeFigma link={interactiveLink} />}
      </div>
    </div>
  );
};

export default PreviewPrototype;
