import Axios from 'axios';

// Endpoint
const EP_PROTOTYPE_INC_PLAY = (prototypeId) => `/prototype/${prototypeId}/play`;

export const incPrototypePlay = (prototypeId) => {
  return Axios.post(EP_PROTOTYPE_INC_PLAY(prototypeId)).then(
    (response) => response.data
  );
};
