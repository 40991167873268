import VoteClap from 'components/VoteClap';
import {hasFlag} from 'helpers/bitwise';
import React from 'react';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import {
  ROUTE_STANDALONE_EVOLUTION_ID,
  ROUTE_WIDGET_EVOLUTION_ID,
} from 'router/routes.const';
import {
  F_OPTION_COMMENTS_ENABLED,
  F_OPTION_LIKE_ENABLED,
} from 'services/evolution';
import './_Styles.scss';

export default function EvolutionStats({
  evolution,
  onClap,
  voteClapProps,
  widgetMode,
  disableCommentLink = false,
  hideComment = false,
}) {
  const hasLikeEnabled = hasFlag(F_OPTION_LIKE_ENABLED, evolution.optionsFlags);
  const hasCommentEnabled = hasFlag(
    F_OPTION_COMMENTS_ENABLED,
    evolution.optionsFlags
  );

  if (hasLikeEnabled === false && hasCommentEnabled === false) {
    return <></>;
  }
  return (
    <div className="evolution-stats">
      {hasLikeEnabled === true && (
        <div className="vote-clap-wrapper">
          <VoteClap
            compact
            clapped={evolution.myVote != null}
            evolution={evolution}
            onClap={onClap}
            {...voteClapProps}
          />
        </div>
      )}
      {hideComment !== true &&
        hasFlag(F_OPTION_COMMENTS_ENABLED, evolution.optionsFlags) && (
          <div className="stat-comment">
            {disableCommentLink === false ? (
              <Link
                to={
                  widgetMode === true
                    ? ROUTE_WIDGET_EVOLUTION_ID(evolution.uid)
                    : ROUTE_STANDALONE_EVOLUTION_ID(evolution.uid)
                }>
                <i className="icon-comment-o"></i>
                <span>
                  {evolution.countComments || 0}{' '}
                  <span className="comment-text">
                    <Trans
                      i18nKey="common.comments"
                      count={evolution.countComments || 0}></Trans>
                  </span>
                </span>
              </Link>
            ) : (
              <div className="stat-comments-wrapper">
                <i className="icon-comment-o"></i>
                <span>
                  {evolution.countComments || 0}{' '}
                  <span className="comment-text">
                    <Trans
                      i18nKey="common.comments"
                      count={evolution.countComments || 0}></Trans>
                  </span>
                </span>
              </div>
            )}
          </div>
        )}
    </div>
  );
}
