import React from 'react';
import classnames from 'classnames';
import {string, func} from 'prop-types';
import './_Styles.scss';

const propTypes = {
  value: string,
  onChange: func.isRequired,
};

const defaultProps = {
  value: null,
};

const Nps = ({value, onChange}) => {
  return (
    <div className="nps">
      <button
        className={classnames('detractor', {selected: value === '0'})}
        onClick={() => onChange('0')}>
        0
      </button>
      <button
        className={classnames('detractor', {selected: value === '1'})}
        onClick={() => onChange('1')}>
        1
      </button>
      <button
        className={classnames('detractor', {selected: value === '2'})}
        onClick={() => onChange('2')}>
        2
      </button>
      <button
        className={classnames('detractor', {selected: value === '3'})}
        onClick={() => onChange('3')}>
        3
      </button>
      <button
        className={classnames('detractor', {selected: value === '4'})}
        onClick={() => onChange('4')}>
        4
      </button>
      <button
        className={classnames('detractor', {selected: value === '5'})}
        onClick={() => onChange('5')}>
        5
      </button>
      <button
        className={classnames('detractor', {selected: value === '6'})}
        onClick={() => onChange('6')}>
        6
      </button>
      <button
        className={classnames('passive', {selected: value === '7'})}
        onClick={() => onChange('7')}>
        7
      </button>
      <button
        className={classnames('passive', {selected: value === '8'})}
        onClick={() => onChange('8')}>
        8
      </button>
      <button
        className={classnames('promoter', {selected: value === '9'})}
        onClick={() => onChange('9')}>
        9
      </button>
      <button
        className={classnames('promoter', {selected: value === '10'})}
        onClick={() => onChange('10')}>
        10
      </button>
    </div>
  );
};

Nps.propTypes = propTypes;
Nps.defaultProps = defaultProps;

export default Nps;
