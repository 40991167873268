// Routes for Standalone
export const ROUTE_STANDALONE_ROADMAP = '/roadmap';
export const ROUTE_STANDALONE_FEEDBACK = '/feedbacks';
export const ROUTE_STANDALONE_EVOLUTION_ID = (evolutionId = ':evolutionId') =>
  `/evolution/${evolutionId}`;
export const ROUTE_STANDALONE_RELEASE_ID = (evolutionId = ':evolutionId') =>
  `/release/${evolutionId}`;
export const ROUTE_STANDALONE_SETTINGS = '/myprofile';
export const ROUTE_STANDALONE_FEED = '/feed';
export const ROUTE_STANDALONE_ENTER = '/';

// Routes for Widget
export const ROUTE_WIDGET_EVOLUTION_ID = (evolutionId = ':evolutionId') =>
  `/w/evolution/${evolutionId}`;
export const ROUTE_WIDGET_ENTER = `/w/enter`;
export const ROUTE_WIDGET_FEED = `/w/feed`;
export const ROUTE_WIDGET_FEEDBACK = `/w/feedback`;
export const ROUTE_WIDGET_ROADMAP = `/w/roadmap`;

export const ROUTE_INTERCOM_EVOLUTION_ID = (evolutionId = ':evolutionId') =>
  `/i/evolution/${evolutionId}`;
export const ROUTE_INTERCOM_ENTER = `/i/enter`;
export const ROUTE_INTERCOM_FEED = `/i/feed`;
export const ROUTE_INTERCOM_FEEDBACK = `/i/feedback`;
export const ROUTE_INTERCOM_ROADMAP = `/i/roadmap`;

// Route for Preview (same scene for Widget and Standalone but with a different URL)
export const ROUTE_WIDGET_PREVIEW = (
  evolutionId = ':evolutionId',
  stepId = ':stepId'
) => `/w/preview/${evolutionId}/${stepId}`;
export const ROUTE_STANDALONE_PREVIEW = (
  evolutionId = ':evolutionId',
  stepId = ':stepId'
) => `/s/preview/${evolutionId}/${stepId}`;

// Route for Dalaran widget and portal appearance live preview
export const ROUTE_DALARAN_LIVE = (mode) => `/dalaran/live/${mode}`;

// Route for Boosted
export const ROUTE_BOOSTED_EVOLUTION_ID = (evolutionId = ':evolutionId') =>
  `/b/evolution/${evolutionId}`;

// Route for auth
export const ROUTE_WIDGET_AUTH = `/w/jimerauth`;

// Route for Poke (old boosted)
export const ROUTE_POKE = (evolutionId = ':evolutionId') =>
  `/poke/${evolutionId}`;
