import classnames from 'classnames';
import i18n from 'conf/i18n';
import {hasFlag} from 'helpers/bitwise';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import {
  ROUTE_STANDALONE_EVOLUTION_ID,
  ROUTE_WIDGET_EVOLUTION_ID,
} from 'router/routes.const';
import {F_OPTION_COMMENTS_ENABLED} from 'services/evolution';
import Content from '../Content';
import Title from '../Title';

const propTypes = {
  evolution: PropTypes.object.isRequired,
  currentStep: PropTypes.object,
  showStatus: PropTypes.bool,
  showTimestamp: PropTypes.bool,
  boosted: PropTypes.bool,
  widgetMode: PropTypes.bool,
};

const defaultProps = {
  currentStep: null,
  showStatus: false,
  showTimestamp: false,
  totalStepsLength: 0,
  boosted: false,
  widgetMode: false,
};

const SurveyQuestion = ({
  currentStep,
  evolution,
  showStatus,
  showTimestamp,
  boosted,
  widgetMode,
}) => {
  const question =
    currentStep?.texts?.find((t) => t.language === i18n.language.toUpperCase())
      ?.question || currentStep?.question;
  const content =
    currentStep?.texts?.find((t) => t.language === i18n.language.toUpperCase())
      ?.additionalInformation || currentStep?.additionalInformation;
  const isTitleWrappedInLink = hasFlag(
    evolution.optionsFlags,
    F_OPTION_COMMENTS_ENABLED
  );

  return (
    <>
      <div className="infos">
        {isTitleWrappedInLink ? (
          <Link
            to={
              boosted === true
                ? '#'
                : widgetMode === true
                ? ROUTE_WIDGET_EVOLUTION_ID(evolution.uid)
                : ROUTE_STANDALONE_EVOLUTION_ID(evolution.uid)
            }
            className={classnames({
              'prevent-hover': boosted === true,
            })}>
            <Title
              question={question}
              evolution={evolution}
              showTimestamp={showStatus === false && showTimestamp === true}
              widgetMode={widgetMode}
            />
          </Link>
        ) : (
          <Title
            showTimestamp={showStatus === false && showTimestamp === true}
            question={question}
            evolution={evolution}
            widgetMode={widgetMode}
          />
        )}
        <Content evolution={evolution} content={content} />
      </div>
    </>
  );
};

SurveyQuestion.propTypes = propTypes;
SurveyQuestion.defaultProps = defaultProps;

export default SurveyQuestion;
