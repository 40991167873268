import PropTypes from 'prop-types';
import React from 'react';
import Nps from '../../components/Nps';

const propTypes = {
  setValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  setValue: () => {},
  value: '',
};

const NpsComponent = ({setValue, value}) => {
  return (
    <>
      <div className="nps-wrapper fade-in-right">
        <Nps value={value} onChange={setValue} />
      </div>
    </>
  );
};

NpsComponent.propTypes = propTypes;
NpsComponent.defaultProps = defaultProps;

export default NpsComponent;
