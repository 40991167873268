import classnames from 'classnames';
import AnalyticViewTrigger from 'components/AnalyticViewTrigger';
import {array, bool, func, object, string} from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {stepsService, surveyService} from 'services';
import {EventContext, EventType} from 'services/analytic';
import {
  STEP_TYPE_CONCEPT_TEST,
  STEP_TYPE_INTERVIEW,
  STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
  STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
  STEP_TYPE_NPS,
  STEP_TYPE_OPINION_SCALE,
  STEP_TYPE_SLIDER,
  STEP_TYPE_TEXT_BLOCK,
  STEP_TYPE_TEXT_LONG,
} from 'services/steps';

import Actions from './components/Actions';
import ProgressBarComponent from './components/ProgressBar';
import Status from './components/Status';
import SurveyContent from './components/SurveyContent';
import SurveyQuestion from './components/SurveyQuestion';
import Success from './steps/Success';
import Text from './steps/Text';
import {areEqual} from './utils';
import './_styles.scss';

const propTypes = {
  setInnerSurveyHeight: func,
  steps: array.isRequired,
  responses: array,
  preview: bool,
  widgetMode: bool,
  boosted: bool,
  onSurveyEnd: func.isRequired,
  onTriggerFocused: func,
  onStepChange: func,
  onConceptTestClick: func,
  onBookSlotClick: func,
  startFrom: object,
  hideStatus: bool,
  showTimestamp: bool,
  builderSelectedStepId: string,
  onDismiss: func,
  onClose: func,
  isIframeVisible: bool,
  setCurrentStepId: func,
  setIsLastTotalStep: func,
  onImageClick: func,
};

const defaultProps = {
  setInnerSurveyHeight: () => {},
  responses: [],
  preview: false,
  widgetMode: false,
  boosted: false,
  onSurveyEnd: () => {},
  onTriggerFocused: () => {},
  onStepChange: () => {},
  startFrom: null,
  hideStatus: false,
  onConceptTestClick: () => {},
  onBookSlotClick: () => {},
  showTimestamp: false,
  builderSelectedStepId: null,
  onDismiss: () => {},
  onClose: () => {},
  isIframeVisible: false,
  setCurrentStepId: () => {},
  setIsLastTotalStep: () => {},
  onImageClick: null,
};

const operators = {
  EQUAL_TO: 'EQUAL_TO',
  NOT_EQUAL_TO: 'NOT_EQUAL_TO',
  GREATER_THAN: 'GREATER_THAN',
  LESSER_THAN: 'LESSER_THAN',
  CONTAINS: 'CONTAINS',
  DOES_NOT_CONTAIN: 'DOES_NOT_CONTAIN',
};

const Survey = ({
  setInnerSurveyHeight,
  evolution,
  steps,
  responses,
  preview,
  widgetMode,
  boosted,
  onTriggerFocused,
  onStepChange,
  onSurveyEnd,
  onConceptTestClick,
  onBookSlotClick,
  startFrom,
  hideStatus,
  showTimestamp,
  builderSelectedStepId,
  onDismiss,
  onClose,
  isIframeVisible,
  setCurrentStepId,
  setIsLastTotalStep,
  onImageClick,
}) => {
  const classNames = classnames('survey', {
    boosted,
  });

  const ref = useRef();

  steps.sort((a, b) => a.indexOrder - b.indexOrder);

  const startingIndex =
    startFrom?.uid != null ? steps.map((s) => s.uid).indexOf(startFrom.uid) : 0;

  const [stepIndex, setStepIndex] = useState(startingIndex);
  const [value, setValue] = useState(null);
  const [surveyEnded, setSurveyEnded] = useState(false);
  const [hideSurvey, setHideSurvey] = useState(false);

  const totalStepsLength =
    steps.length + (evolution.stepsBefore || 0) + (evolution.stepsAfter || 0);
  const currentStep = steps[stepIndex];
  const totalStepIdex = stepIndex + (evolution.stepsBefore || 0);
  const isLastTotalStep = totalStepIdex === totalStepsLength - 1;
  const isLastStep = stepIndex === steps.length - 1;
  const hasNextTourStep = evolution.stepsAfter > 0;
  const nextStepIsLastAndTextBlock =
    steps[stepIndex + 1] != null &&
    steps[stepIndex + 1].type === STEP_TYPE_TEXT_BLOCK &&
    stepIndex + 2 === steps.length &&
    hasNextTourStep !== true;

  useEffect(() => {
    setCurrentStepId(currentStep?.uid);
  }, [currentStep, setCurrentStepId]);

  useEffect(() => {
    setIsLastTotalStep(isLastTotalStep);
  }, [isLastTotalStep, setIsLastTotalStep]);

  useEffect(() => {
    if (builderSelectedStepId != null) {
      const index = steps.map((s) => s.uid).indexOf(builderSelectedStepId);
      if (index >= 0) {
        setStepIndex(index);
      }
    }
  }, [builderSelectedStepId, steps]);

  useEffect(() => {
    setInnerSurveyHeight(ref.current?.offsetHeight);
  }, [ref, setInnerSurveyHeight]);

  useEffect(() => {
    if (preview === true) {
      return;
    }
    if (stepIndex > 0) {
      if (
        stepIndex + 1 === steps.length &&
        currentStep.type === STEP_TYPE_TEXT_BLOCK &&
        boosted !== true
      ) {
        return onSurveyEnd();
      }
      onTriggerFocused();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepIndex]);

  useEffect(() => {
    onStepChange(currentStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepIndex]);

  useEffect(() => {
    if (
      [
        stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
        stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
      ].includes(currentStep?.type)
    ) {
      setValue([]);
    } else if (currentStep?.type === stepsService.STEP_TYPE_TEXT_LONG) {
      setValue('');
    } else if (currentStep?.type === stepsService.STEP_TYPE_SLIDER) {
      setValue(670);
    } else if (currentStep?.type === stepsService.STEP_TYPE_NPS) {
      setValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  // useEffect(() => {
  //   if (currentStep.type !== STEP_TYPE_TEXT_BLOCK) {
  //     return;
  //   }
  //   if (currentStep.ctaBorderRadius != null) {
  //     document.documentElement.style.setProperty(
  //       '--btnNextBorderRadius',
  //       `${currentStep.ctaBorderRadius}px`
  //     );
  //   } else {
  //     document.documentElement.style.setProperty(
  //       '--btnNextBorderRadius',
  //       `40px`
  //     );
  //   }
  //   if (widgetMode === true) {
  //     document.documentElement.style.setProperty(
  //       '--btnNextBackgroundColor',
  //       `${evolution.boosted.boostedSecondaryColor}`
  //     );
  //   } else {
  //     document.documentElement.style.setProperty(
  //       '--btnNextBackgroundColor',
  //       '#4099f7'
  //     );
  //   }
  // }, [currentStep]);

  useEffect(() => {
    const response = responses.find((r) => r?.step?.uid === currentStep.uid);

    if (response != null) {
      // eslint-disable-next-line default-case
      switch (currentStep.type) {
        case stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT: {
          setValue(
            currentStep.options.filter((o) =>
              response.selectedOptions.map((s) => s.uid).includes(o.uid)
            )
          );
          break;
        }
        case stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT: {
          setValue(
            currentStep.options.find(
              (o) => o.uid === response.selectedOptions?.[0]?.uid
            )
          );
          break;
        }
        case stepsService.STEP_TYPE_OPINION_SCALE: {
          setValue(parseInt(response.value, 10));
          break;
        }
        case stepsService.STEP_TYPE_TEXT_LONG: {
          setValue(response.value);
          break;
        }
        case stepsService.STEP_TYPE_SLIDER: {
          setValue(parseInt(response.value, 10));
          break;
        }
        case stepsService.STEP_TYPE_NPS: {
          setValue(response.value);
          break;
        }
      }
    }
  }, [currentStep, responses]);

  const handleSubmit = async (emojiValue) => {
    // send survey data to api
    const data = {};
    const {jumps = []} = currentStep;
    let matchingJump = null;

    if (
      currentStep?.type === stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
    ) {
      data.selectedOptions = value;
      matchingJump = jumps.find((j) => {
        if (j.conditionOperator === 'ALL' || j.conditionOperator == null) {
          return areEqual(
            j.optionValues.map((o) => o.uid || o),
            value.map((v) => v.uid)
          );
        }
        if (j.conditionOperator === 'NOT') {
          return !areEqual(
            j.optionValues.map((o) => o.uid || o),
            value.map((v) => v.uid)
          );
        }
        if (j.conditionOperator === 'AT_LEAST_ONE_OF') {
          return value.some((v) =>
            j.optionValues.map((o) => o.uid || o).includes(v.uid)
          );
        }
        if (j.conditionOperator === 'NONE_OF') {
          return !value.some((v) =>
            j.optionValues.map((o) => o.uid || o).includes(v.uid)
          );
        }
        return false;
      });
    }
    if (
      currentStep?.type === stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT
    ) {
      data.selectedOptions = value != null ? [value] : null;
      matchingJump = jumps.find((j) => {
        if (j.conditionOperator === 'ALL' || j.conditionOperator == null) {
          return areEqual(
            j.optionValues.map((o) => o.uid || o),
            [value?.uid]
          );
        }
        if (j.conditionOperator === 'NOT') {
          return !areEqual(
            j.optionValues.map((o) => o.uid || o),
            [value?.uid]
          );
        }
        if (j.conditionOperator === 'AT_LEAST_ONE_OF') {
          return [value?.uid].some((v) =>
            j.optionValues.map((o) => o.uid || o).includes(v)
          );
        }
        if (j.conditionOperator === 'NONE_OF') {
          return ![value?.uid].some((v) =>
            j.optionValues.map((o) => o.uid || o).includes(v)
          );
        }
        return false;
      });
    }
    if (currentStep?.type === stepsService.STEP_TYPE_TEXT_LONG) {
      data.value = value;
      matchingJump = jumps.find((j) => {
        if (j.operator === operators.EQUAL_TO) {
          return j.value === value;
        }
        if (j.operator === operators.NOT_EQUAL_TO) {
          return j.value !== value;
        }
        if (j.operator === operators.CONTAINS) {
          return value?.includes?.(j.value) === true;
        }
        if (j.operator === operators.DOES_NOT_CONTAIN) {
          return value?.includes?.(j.value) === false;
        }
        return null;
      });
    }
    if (currentStep?.type === stepsService.STEP_TYPE_OPINION_SCALE) {
      data.value = emojiValue.toString();
      matchingJump = jumps.find((j) => {
        if (j.conditionOperator === 'ALL' || j.conditionOperator == null) {
          return j.value === emojiValue.toString();
        }
        if (j.conditionOperator === 'NOT') {
          return j.value !== emojiValue.toString();
        }
        if (j.conditionOperator === 'AT_LEAST_ONE_OF') {
          return (j.value || '')
            .split(';')
            .map((o) => o)
            .includes(emojiValue.toString());
        }
        if (j.conditionOperator === 'NONE_OF') {
          return !(j.value || '')
            .split(';')
            .map((o) => o)
            .includes(emojiValue.toString());
        }
        return null;
      });
    }
    if (currentStep?.type === stepsService.STEP_TYPE_SLIDER) {
      data.value = value.toString();
      matchingJump = jumps.find((j) => {
        if (j.operator === operators.EQUAL_TO) {
          return parseInt(j.value, 10) === parseInt(value / 10, 10);
        }
        if (j.operator === operators.NOT_EQUAL_TO) {
          return parseInt(j.value, 10) !== parseInt(value / 10, 10);
        }
        if (j.operator === operators.GREATER_THAN) {
          return parseInt(j.value, 10) < parseInt(value / 10, 10);
        }
        if (j.operator === operators.LESSER_THAN) {
          return parseInt(j.value, 10) > parseInt(value / 10, 10);
        }
        return null;
      });
    }
    if (currentStep?.type === stepsService.STEP_TYPE_NPS) {
      data.value = value;
      matchingJump = jumps.find((j) => {
        if (j.operator === operators.EQUAL_TO) {
          return parseInt(j.value, 10) === parseInt(value, 10);
        }
        if (j.operator === operators.NOT_EQUAL_TO) {
          return parseInt(j.value, 10) !== parseInt(value, 10);
        }
        if (j.operator === operators.GREATER_THAN) {
          return parseInt(j.value, 10) < parseInt(value, 10);
        }
        if (j.operator === operators.LESSER_THAN) {
          return parseInt(j.value, 10) > parseInt(value, 10);
        }
        return null;
      });
    }

    if (preview !== true) {
      surveyService.createResponse({
        evolutionId: evolution.uid,
        stepId: currentStep.uid,
        ...data,
      });
    }

    if (
      stepIndex + 1 === steps.length ||
      matchingJump?.endSurvey === true ||
      (matchingJump == null && currentStep?.endSurvey === true) ||
      nextStepIsLastAndTextBlock
    ) {
      if (nextStepIsLastAndTextBlock === true) {
        setStepIndex(stepIndex + 1);
      } else {
        // doesn't activate animation if is tour and not last step
        if (hasNextTourStep) {
          return onSurveyEnd();
        } else if (steps.length <= 1) {
          setSurveyEnded(true);
          setStepIndex(0);
        }
        // activate animation if more than 1 step
        else {
          setSurveyEnded(true);
        }
      }
      if (preview !== true) {
        surveyService.surveyCompleted({
          evolutionId: evolution.uid,
        });
      }
    } else {
      let nextStepIndex = stepIndex + 1;
      if (matchingJump != null) {
        const index = steps
          .map((s) => s.uid)
          .indexOf(matchingJump.goTo?.uid || matchingJump.goTo);
        if (index > 0) {
          nextStepIndex = index;
        }
      } else if (currentStep?.goTo != null) {
        const index = steps
          .map((s) => s.uid)
          .indexOf(currentStep.goTo?.uid || currentStep.goTo);
        if (index > 0) {
          nextStepIndex = index;
        }
      }
      if (
        steps[nextStepIndex].type ===
        stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT
      ) {
        setValue([]);
      }
      if (
        [
          stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
          stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
        ].includes(steps[nextStepIndex].type)
      ) {
        setValue(null);
      }
      if (steps[nextStepIndex].type === stepsService.STEP_TYPE_TEXT_LONG) {
        setValue('');
      }
      if (steps[nextStepIndex].type === stepsService.STEP_TYPE_SLIDER) {
        setValue(670);
      }
      if (steps[nextStepIndex].type === stepsService.STEP_TYPE_NPS) {
        setValue(null);
      }

      setStepIndex(nextStepIndex);
    }
  };

  const showStatus = hideStatus === false && evolution?.tags?.length > 0;

  const isSurveyBlock = [
    STEP_TYPE_CONCEPT_TEST,
    STEP_TYPE_INTERVIEW,
    STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
    STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    STEP_TYPE_NPS,
    STEP_TYPE_OPINION_SCALE,
    STEP_TYPE_SLIDER,
    STEP_TYPE_TEXT_LONG,
  ].includes(currentStep?.type);
  const isFreeContentBlock = currentStep?.type === STEP_TYPE_TEXT_BLOCK;
  const context =
    boosted === true
      ? EventContext.IN_APP
      : widgetMode === true
      ? EventContext.PORTAL_WIDGET
      : EventContext.PORTAL_STANDALONE;

  if (hideSurvey) {
    return <></>;
  }

  if (surveyEnded === true) {
    return (
      <div className={classNames}>
        <Success
          onSuccessCompleted={() => {
            setSurveyEnded(false);
            setStepIndex(0);
            onSurveyEnd();
            if (boosted) {
              setHideSurvey(true);
            }
          }}
        />
      </div>
    );
  }

  return (
    <div className={classNames}>
      <div
        className={classnames('survey-header-row', {
          'without-progress-bar': totalStepsLength <= 1,
          'with-status': showStatus,
        })}>
        <ProgressBarComponent
          stepIndex={stepIndex}
          evolution={evolution}
          totalStepsLength={totalStepsLength}
        />
        {boosted && (
          <div
            className="close-wrapper"
            onClick={() => {
              if (isLastTotalStep === true && isFreeContentBlock) {
                onClose();
              } else {
                onDismiss();
              }
            }}>
            <i className="icon-close" />
          </div>
        )}
      </div>
      {showStatus && <Status evolution={evolution} boosted={boosted} />}
      <div ref={ref} className="survey-scroll-wrapper">
        <div className="fade-in-right" key={currentStep?.uid}>
          {preview !== true && (boosted !== true || isIframeVisible) && (
            <AnalyticViewTrigger
              evolution={evolution}
              context={context}
              type={EventType.STEP_SEEN}
              step={currentStep}
            />
          )}
          {isSurveyBlock && (
            <>
              <SurveyQuestion
                currentStep={currentStep}
                evolution={evolution}
                showStatus={showStatus}
                showTimestamp={showTimestamp}
                boosted={boosted}
                widgetMode={widgetMode}
              />
              <SurveyContent
                evolution={evolution}
                currentStep={currentStep}
                setValue={setValue}
                handleSubmit={handleSubmit}
                value={value}
                isLastStep={isLastStep}
                isLastTotalStep={isLastTotalStep}
                setStepIndex={setStepIndex}
                boosted={boosted}
                onConceptTestClick={onConceptTestClick}
                onSurveyEnd={onSurveyEnd}
                totalStepsLength={totalStepsLength}
                onDismiss={onDismiss}
              />
            </>
          )}
          {isFreeContentBlock && (
            <Text
              evolution={evolution}
              preview={preview}
              isLastStep={isLastStep}
              boosted={boosted}
              widgetMode={widgetMode}
              onSurveyEnd={onSurveyEnd}
              currentStep={currentStep}
              showStatus={showStatus}
              showTimestamp={showTimestamp}
              stepIndex={stepIndex}
              totalStepsLength={totalStepsLength}
              onImageClick={onImageClick}
            />
          )}
        </div>
      </div>
      <Actions
        boosted={boosted}
        currentStep={currentStep}
        evolution={evolution}
        isLastStep={isLastStep}
        isLastTotalStep={isLastTotalStep}
        onSurveyEnd={onSurveyEnd}
        setStepIndex={setStepIndex}
        stepIndex={stepIndex}
        onDismiss={onDismiss}
        handleSubmit={handleSubmit}
        value={value}
        totalStepsLength={totalStepsLength}
        onBookSlotClick={onBookSlotClick}
        widgetMode={widgetMode}
      />
    </div>
  );
};

Survey.propTypes = propTypes;
Survey.defaultProps = defaultProps;

export default Survey;
