import {generalActions} from 'actions';
import Survey from 'components/CardPush/components/Survey';
import i18n from 'conf/i18n';
import {bool, func, object, string} from 'prop-types';
import React from 'react';
import {useDispatch} from 'react-redux';
import {evolutionService} from 'services';
import './_Styles.scss';

const propTypes = {
  evolution: object.isRequired,
  step: object.isRequired,
  onSurveyEnd: func,
  isBoostedPreview: bool,
  builderSelectedStepId: string,
};

const defaultProps = {
  onSurveyEnd: () => {},
  isBoostedPreview: false,
  builderSelectedStepId: null,
};

const PreviewSidebar = ({
  evolution,
  step,
  onSurveyEnd,
  isBoostedPreview,
  builderSelectedStepId,
}) => {
  const dispatch = useDispatch();

  const uptEvolutionById = (data) =>
    dispatch(generalActions.uptEvolutionById(evolution.uid, data));

  const fetchEvolutionWithResponses = async () => {
    const evolutionWithResponses = await evolutionService.getEvolutionById(
      evolution.uid,
      {
        relations: [
          'count.steps.responses',
          'myVote',
          'mySurvey',
          'texts',
          'tags',
          'steps',
          'steps.thumbnails',
        ],
      }
    );

    uptEvolutionById(evolutionWithResponses);
    return evolutionWithResponses;
  };

  const question =
    step?.texts?.find((t) => t.language === i18n.language.toUpperCase())
      ?.question || step?.question;
  const content =
    step?.texts?.find((t) => t.language === i18n.language.toUpperCase())
      ?.additionalInformation || step?.additionalInformation;
  const prototypeSteps = step.prototypes[0].steps;

  return (
    <div className="preview-sidebar-wrapper">
      <div className="preview-sidebar">
        <div className="scroll-view">
          {prototypeSteps.length > 0 ? (
            <Survey
              evolution={evolution}
              steps={prototypeSteps || []}
              responses={evolution.mySurvey?.responses ?? []}
              onSurveyEnd={async () => {
                if (isBoostedPreview === true) {
                  return onSurveyEnd();
                }
                const evolutionWithResponses =
                  await fetchEvolutionWithResponses();

                onSurveyEnd(evolutionWithResponses);
              }}
              preview={isBoostedPreview}
              builderSelectedStepId={builderSelectedStepId}
            />
          ) : (
            <>
              <div className="question">{question}</div>
              <div className="additionalInformation">{content}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

PreviewSidebar.propTypes = propTypes;
PreviewSidebar.defaultProps = defaultProps;

export default PreviewSidebar;
