import Axios from 'axios';
import {generalSelector} from 'selectors';

export const F_OPTION_COMMENTS_ENABLED = 1;
export const F_OPTION_SHOW_ON_WIDGET = 2;
export const F_OPTION_SHOW_ON_PORTAL = 4;
export const F_OPTION_LIKE_ENABLED = 8;
export const F_OPTION_CTA_IN_NEW_TAB = 32; //@deprecated, moved to step service
export const F_OPTION_PORTAL_DISPLAY_FEED = 64;
export const F_OPTION_PORTAL_DISPLAY_ROADMAP = 128;
export const F_OPTION_PORTAL_DISPLAY_FEEDBACK = 256;
export const F_OPTION_BUILDER_PRIORITIZE_IN_APP = 512;
export const F_OPTION_OPEN_WIDGET_ON_CLICK = 1024;
export const F_OPTION_DISMISS_ON_OUTSIDE_CLICK = 2048;
export const F_OPTION_DOT_SHOW_ON_HOVER = 4096;
export const F_OPTION_PROGRESS_ON_TARGET_CLICK = 16384;
export const F_OPTION_TOUR_STEP_SKIPPABLE = 32768;

// Endpoint
const EP_EVOLUTION_GET = '/evolution';
const EP_EVOLUTION_GET_BY_ID = (evolutionId) => `/evolution/${evolutionId}`;
const EP_EVOLUTION_COUNT = '/evolution/count';

// Methods
export const getEvolutions = (data = {}, onlyForMode) => {
  const projectId = generalSelector.getProjectId();
  const {step, relations = []} = data;

  return Axios.get(EP_EVOLUTION_GET, {
    params: {step, projectId, relations, onlyForMode},
  }).then((response) => response.data);
};
export const getEvolutionById = (evolutionId, opts) => {
  const {relations, isPreview} = opts;

  return Axios.get(EP_EVOLUTION_GET_BY_ID(evolutionId), {
    params: {relations, isPreview},
  }).then((response) => response.data);
};
export const countEvolutions = (data) => {
  const {projectId = generalSelector.getProjectId()} = data;

  return Axios.get(EP_EVOLUTION_COUNT, {params: {projectId}}).then(
    (response) => response.data
  );
};
