import Store from 'store/configure';

export const isUserIdentified = () => {
  const store = Store().get().store.getState();

  if (!store.user) {
    return false;
  }
  if (Object.keys(store.user).length === 0) {
    return false;
  }
  return true;
};
