import React from 'react';

export default function IframeFigma(props) {
  const link = `https://www.figma.com/embed?embed_host=astra&url=${props.link}`;

  return (
    <iframe
      src={link}
      frameborder="0"
      title="iframe_preview_preview"
      className="preview-iframe-figma"></iframe>
  );
}
