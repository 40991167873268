import classnames from 'classnames';
import AnalyticViewTrigger from 'components/AnalyticViewTrigger';
import Button from 'components/Button';
import CardPush from 'components/CardPush';
import EvolutionStats from 'components/EvolutionStats';
import i18n from 'conf/i18n';
import {hasFlag} from 'helpers/bitwise';
import useStormwindMessenger from 'hooks/useStormwindMessenger';
import {Messenger} from 'managers/messenger';
import queryString from 'query-string';
import React, {createContext, useContext, useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {ROUTE_WIDGET_FEEDBACK} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {EventContext, EventType} from 'services/analytic';
import {F_OPTION_PORTAL_DISPLAY_FEED} from 'services/evolution';
import {
  F_EXTRA_WIDGET_COLLAPSE_POST,
  F_PAGE_FEED,
  F_PAGE_FEEDBACK,
} from 'services/project';
import {Post} from 'shared/front/components/Post';
import {EmptyStateFeed} from '../../../components/EmptyStateFeed';
import './_Styles.scss';
import {generalActions} from 'actions';
import {AppContext} from 'App';

const POST_PREVIEW_REQUEST = 'POST_PREVIEW_REQUEST';

export const FeedContext = createContext();

const Feed = ({isIntercom}) => {
  const dispatch = useDispatch();

  let evolutions = useSelector((state) => generalSelector.getEvolutions(state));
  const project = useSelector((state) => generalSelector.getProject(state));
  const previewedPost = useSelector((state) => state.general.previewedPost);

  const {addFontFamily} = useContext(AppContext);

  const [focused, setFocused] = useState(null);
  const [overrideTheme, setOverrideTheme] = useState(null);
  const [overrideLanguage, setOverrideLanguage] = useState(null);

  const qs = queryString.parse(window.location.search);
  const isPreview = qs.preview === 'true';

  useStormwindMessenger({
    onPostPreviewUpdate: ({evolution}) => {
      dispatch(generalActions.setPreviewedPost(evolution));
    },
    onChangelogOverrideTheme: ({theme}) => {
      setOverrideTheme(theme);
    },
    onChangelogOverrideLanguage: ({language}) => {
      setOverrideLanguage(language);
    },
  });

  useEffect(() => {
    if (isPreview === true) {
      const interval = setInterval(() => {
        if (window.parentIFrame != null) {
          window.parentIFrame.sendMessage(
            JSON.stringify({action: POST_PREVIEW_REQUEST, fromJimo: true})
          );
          clearInterval(interval);
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classNames = classnames('widget-feed', {
    'has-feedback-enabled':
      hasFlag(F_PAGE_FEEDBACK, project.widgetContentFlags) === true,
    'has-whitelabel': project.whiteLabeling === true,
    'is-preview': isPreview === true,
  });

  if (hasFlag(F_PAGE_FEED, project.widgetContentFlags) === false) {
    return <Redirect to={ROUTE_WIDGET_FEEDBACK} />;
  }

  evolutions = evolutions.filter(
    (e) =>
      hasFlag(F_OPTION_PORTAL_DISPLAY_FEED, e.optionsFlags) &&
      e.uid !== previewedPost?.uid
  );

  if (previewedPost != null) {
    evolutions = [previewedPost, ...evolutions];
  }

  evolutions.sort((a, b) => {
    // make sure that the previewed post is always at the start of the list
    if (previewedPost != null) {
      if (a.uid === previewedPost.uid) {
        return -1;
      }
      if (b.uid === previewedPost.uid) {
        return 1;
      }
    }

    return (
      new Date(b.lastStepChangeAt).getTime() -
      new Date(a.lastStepChangeAt).getTime()
    );
  });

  const theme = overrideTheme != null ? overrideTheme : project.changelogStyle;

  const language = isPreview
    ? overrideLanguage != null
      ? overrideLanguage
      : null
    : i18n.language.toUpperCase();

  const enableCollapse = hasFlag(
    F_EXTRA_WIDGET_COLLAPSE_POST,
    project.extraFlags
  );

  return (
    <FeedContext.Provider
      value={{
        isPreview,
        theme,
      }}>
      <div className={classNames}>
        {evolutions.length === 0 ? (
          <EmptyStateFeed />
        ) : (
          <div
            className={classnames('evolutions-wrapper', {
              'is-intercom': isIntercom === true,
            })}>
            {evolutions.map((e) => {
              const isOldVersionPost = !(e?.steps?.[0]?.blocks?.length > 0);

              if (isOldVersionPost) {
                return (
                  <CardPush
                    className="fade-in"
                    key={e.uid}
                    evolution={e}
                    widgetMode
                    focused={focused === e.uid}
                    setFocused={() => setFocused(e.uid)}
                    onClose={() => setFocused(null)}
                    isIntercom={isIntercom}
                    enableCollapse
                    showTimestamp
                    isPreview={isPreview}
                  />
                );
              } else {
                return (
                  <Post
                    post={e}
                    theme={theme}
                    language={language}
                    onLaunchPoke={({pokeId, defaultUrl}) => {
                      Messenger.sendLaunchPoke({
                        pokeId,
                        defaultUrl,
                      });
                    }}
                    onUrlClick={(url) => {
                      Messenger.sendOpenCtaUrl(url);
                    }}
                    onImageClick={(src) => {
                      Messenger.sendOpenModalImage(src);
                    }}
                    PostBlockSocialsContent={EvolutionStats}
                    AnalyticViewTrigger={() => {
                      if (isPreview === true) {
                        return null;
                      }
                      return (
                        <AnalyticViewTrigger
                          evolution={e}
                          context={EventContext.PORTAL_WIDGET}
                          type={EventType.EVOLUTION_SEEN}
                        />
                      );
                    }}
                    enableCollapse={
                      enableCollapse &&
                      (previewedPost?.uid === e.uid &&
                        previewedPost.preventCollapse === true) !== true
                    }
                    ReadMoreOverlay={({onClick}) => {
                      return (
                        <div className="overlay-read-more">
                          <Button onClick={onClick} rounded={false}>
                            <Trans i18nKey="common.readMore" />
                            <i className="icon-chevron-bottom"></i>
                          </Button>
                        </div>
                      );
                    }}
                    addFontFamily={addFontFamily}
                  />
                );
              }
            })}
          </div>
        )}
      </div>
    </FeedContext.Provider>
  );
};

export default Feed;
