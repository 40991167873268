import Axios from 'axios';
import {generalSelector} from 'selectors';

// Endpoint
const EP_TAGS_GET = '/tag';

// Methods
export const getTags = (filter) => {
  const {contexts} = filter;
  const projectId = generalSelector.getProjectId();

  return Axios.get(EP_TAGS_GET, {
    params: {projectId, contexts, relations: ['texts']},
  }).then((response) => response.data);
};
