import CardPush from 'components/CardPush';
import React from 'react';
import './_Styles.scss';

const EvolutionList = ({evolutions, enablePostCollapse, widgetMode}) => (
  <div className="column-evolution-list">
    <div className="wrapper">
      <div className="scroll-container">
        {evolutions.map((evolution) => (
          <CardPush
            key={evolution.uid}
            evolution={evolution}
            hideStatus
            enableCollapse={enablePostCollapse}
            widgetMode={widgetMode}
          />
        ))}
      </div>
    </div>
  </div>
);

export default EvolutionList;
