import classnames from 'classnames';
import Button from 'components/Button';
import fontColorContrast from 'font-color-contrast';
import {hasFlag} from 'helpers/bitwise';
import {func, object, bool, number, any} from 'prop-types';
import React from 'react';
import {Trans} from 'react-i18next';
import {stepsService} from 'services';
import {F_OPTION_TOUR_STEP_SKIPPABLE} from 'services/evolution';
import './_styles.scss';

const propTypes = {
  evolution: object.isRequired,
  step: object.isRequired,
  value: any,
  onVote: func.isRequired,
  isLastTotalStep: bool,
  totalStepsLength: number,
  onDismiss: func,
};

const defaultProps = {
  value: null,
  isLastTotalStep: false,
  totalStepsLength: 0,
  onDismiss: () => {},
};

const Vote = ({
  evolution,
  step,
  value,
  onVote,
  isLastTotalStep,
  totalStepsLength,
  onDismiss,
}) => {
  // const hasSkipButton =
  //   hasFlag(F_OPTION_TOUR_STEP_SKIPPABLE, evolution.optionsFlags) === true &&
  //   isLastTotalStep !== true;
  const hasSkipButton = false;
  let isSubmitDisabled = false;

  // eslint-disable-next-line default-case
  switch (step.type) {
    case stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT: {
      if (value?.length <= 0) {
        isSubmitDisabled = true;
      }
      break;
    }
    case stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT: {
      if (value == null) {
        isSubmitDisabled = true;
      }
      break;
    }
    case stepsService.STEP_TYPE_TEXT_LONG: {
      if (!value) {
        isSubmitDisabled = true;
      }
      break;
    }
    case stepsService.STEP_TYPE_NPS: {
      if (!value) {
        isSubmitDisabled = true;
      }
      break;
    }
  }

  let label;

  if (totalStepsLength > 1) {
    if (isLastTotalStep) {
      label = <Trans i18nKey="common.submit" />;
    } else {
      label = <Trans i18nKey="common.next" />;
    }
  } else {
    if (
      [
        stepsService.STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
        stepsService.STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
      ].includes(step?.type)
    ) {
      label = <Trans i18nKey="common.vote" />;
    } else {
      label = <Trans i18nKey="common.submit" />;
    }
  }

  return (
    <div className="vote-wrapper">
      {step?.responsesCount > 0 && (
        <>
          <div className="number-vote">{step?.responsesCount} votes</div>
        </>
      )}
      <div className="btns-wrapper">
        {hasSkipButton && (
          <Button
            className="btn-skip"
            style={{
              backgroundColor:
                document.documentElement.style.getPropertyValue(
                  '--boostedSecondaryColor'
                ) || '#409af8',
            }}
            onClick={onDismiss}
            primary>
            <Trans i18nKey="common.skip" />
          </Button>
        )}
        <Button
          rounded
          className={classnames('action-btn', {
            disabled: isSubmitDisabled,
          })}
          onClick={onVote}
          disabled={isSubmitDisabled}
          style={{
            backgroundColor: evolution.boostedSecondaryColor,
            color: fontColorContrast(evolution.boostedSecondaryColor),
          }}>
          {label}
        </Button>
      </div>
    </div>
  );
};

Vote.propTypes = propTypes;
Vote.defaultProps = defaultProps;

export default Vote;
