import React from 'react';
import './_Styles.scss';
import {Roadmap} from 'scenes/Standalone/Roadmap';

const RoadmapWrapper = () => {
  return (
    <div className="widget-roadmap-wrapper">
      <div className="board-wrapper fade-in-bottom">
        <Roadmap enablePostCollapse widgetMode />
      </div>
    </div>
  );
};

export default RoadmapWrapper;
