import {hasFlag} from 'helpers/bitwise';
import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {
  ROUTE_STANDALONE_FEED,
  ROUTE_STANDALONE_FEEDBACK,
  ROUTE_STANDALONE_ROADMAP,
} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {F_PAGE_FEED, F_PAGE_FEEDBACK, F_PAGE_ROADMAP} from 'services/project';

const StandaloneEnter = () => {
  const project = useSelector((state) => generalSelector.getProject(state));

  const isFeedActive = hasFlag(F_PAGE_FEED, project.portalContentFlags);
  const isRoadmapActive = hasFlag(F_PAGE_ROADMAP, project.portalContentFlags);
  const isFeedbackActive = hasFlag(F_PAGE_FEEDBACK, project.portalContentFlags);

  const sectionsStr = project.portalSectionOrder || 'feed;roadmap;feedback';
  const sections = sectionsStr.split(';').filter((s) => {
    if (isFeedActive !== true && s === 'feed') {
      return false;
    }
    if (isRoadmapActive !== true && s === 'roadmap') {
      return false;
    }
    if (isFeedbackActive !== true && s === 'feedback') {
      return false;
    }
    return true;
  });

  const firstSection = sections[0];

  if (firstSection === 'feedback') {
    return <Redirect to={ROUTE_STANDALONE_FEEDBACK} />;
  } else if (firstSection === 'roadmap') {
    return <Redirect to={ROUTE_STANDALONE_ROADMAP} />;
  } else {
    return <Redirect to={ROUTE_STANDALONE_FEED} />;
  }
};

export default StandaloneEnter;
