import classNames from 'classnames';
import Button from 'components/Button';
import React from 'react';
import {cssTransition, toast} from 'react-toastify';
import './_Styles.scss';

const TRANSITIONS = {
  'top-left': cssTransition({
    enter: 'fade-in-left',
    exit: 'fade-out-left',
    duration: 250,
  }),
  'top-center': cssTransition({
    enter: 'fade-in-top',
    exit: 'fade-out-top',
    duration: 250,
  }),
  'top-right': cssTransition({
    enter: 'fade-in-right',
    exit: 'fade-out-right',
    duration: 250,
  }),
  'bottom-left': cssTransition({
    enter: 'fade-in-left',
    exit: 'fade-out-left',
    duration: 250,
  }),
  'bottom-center': cssTransition({
    enter: 'fade-in-bottom',
    exit: 'fade-out-bottom',
    duration: 250,
  }),
  'bottom-right': cssTransition({
    enter: 'fade-in-right',
    exit: 'fade-out-right',
    duration: 250,
  }),
};
const DEFAULT_POSITION = toast.POSITION.BOTTOM_RIGHT;

class Toast extends React.Component {
  _getClassName = () => {
    return classNames('toast-content');
  };
  getCenterElementFromChildren = () => {
    const {children} = this.props;
    const [centerElement] = children;

    if (typeof children === 'string') return children;
    return centerElement;
  };
  getSecondLineFromChildren = () => {
    const {children} = this.props;
    const [, ...secondline] = children;

    if (typeof children === 'string') return null;
    return secondline;
  };
  render() {
    const {leftElement, rightElement, action = null} = this.props;
    const centerElement = this.getCenterElementFromChildren();
    const secondLine = this.getSecondLineFromChildren();

    return (
      <div className={this._getClassName()}>
        <div className="left-element">{leftElement}</div>
        <div className="center-element">
          <div className="text">
            {centerElement}
            {secondLine != null && (
              <div className="second-line">{secondLine}</div>
            )}
          </div>
          {action != null && (
            <div className="action">
              <Button {...action.props} muted>
                {action.text}
              </Button>
            </div>
          )}
        </div>
        <div className="right-element">{rightElement}</div>
      </div>
    );
  }
}

export const toastInfo = (content, options = {}) => {
  const transition = options.position
    ? TRANSITIONS[options.position]
    : TRANSITIONS['top-right'];
  const leftElement = () => {
    return (
      <div className="icon-wrapper icon-info-wrapper">
        <i className="icon-info-circle"></i>
      </div>
    );
  };
  const rightElement = () => {
    return <i className="icon-close" onClick={dismiss}></i>;
  };
  const dismiss = () => toast.dismiss(toastId);
  let toastId = null;

  toastId = toast(
    <Toast
      leftElement={leftElement()}
      rightElement={rightElement()}
      action={options.action}>
      {content}
    </Toast>,
    {
      position: DEFAULT_POSITION,
      ...options,
      className: 'jimo-toast-wrapper toast--info',
      transition,
    }
  );
};
export const toastSuccess = (content, options = {}) => {
  const transition = options.position
    ? TRANSITIONS[options.position]
    : TRANSITIONS['bottom-right'];
  const leftElement = () => {
    return (
      <div className="icon-wrapper icon-tick-wrapper">
        <i className="icon-tick-circle"></i>
      </div>
    );
  };
  const rightElement = () => {
    return <i className="icon-close" onClick={dismiss}></i>;
  };
  const dismiss = () => toast.dismiss(toastId);
  let toastId = null;

  toastId = toast(
    <Toast
      leftElement={leftElement()}
      rightElement={rightElement()}
      action={options.action}>
      {content}
    </Toast>,
    {
      position: DEFAULT_POSITION,
      ...options,
      className: 'jimo-toast-wrapper toast--success',
      transition,
    }
  );
};
export const toastDanger = (content, options = {}) => {
  const transition = options.position
    ? TRANSITIONS[options.position]
    : TRANSITIONS['top-right'];
  const leftElement = () => {
    return (
      <div className="icon-wrapper icon-exclamation-wrapper">
        <i className="icon-exclamation-circle"></i>
      </div>
    );
  };
  const rightElement = () => {
    return <i className="icon-close" onClick={dismiss}></i>;
  };
  const dismiss = () => toast.dismiss(toastId);
  let toastId = null;

  toastId = toast(
    <Toast
      leftElement={leftElement()}
      rightElement={rightElement()}
      action={options.action}>
      {content}
    </Toast>,
    {
      position: DEFAULT_POSITION,
      ...options,
      className: 'jimo-toast-wrapper toast--danger',
      transition,
    }
  );
};
export const toastWarning = (content, options = {}) => {
  const transition = options.position
    ? TRANSITIONS[options.position]
    : TRANSITIONS['top-right'];
  const leftElement = () => {
    return (
      <div className="icon-wrapper icon-exclamation-wrapper">
        <i className="icon-exclamation-triangle"></i>
      </div>
    );
  };
  const rightElement = () => {
    return <i className="icon-close" onClick={dismiss}></i>;
  };
  const dismiss = () => toast.dismiss(toastId);
  let toastId = null;

  toastId = toast(
    <Toast
      leftElement={leftElement()}
      rightElement={rightElement()}
      action={options.action}>
      {content}
    </Toast>,
    {
      position: DEFAULT_POSITION,
      ...options,
      className: 'jimo-toast-wrapper toast--warning',
      transition,
    }
  );
};
