import classnames from 'classnames';
import AnalyticViewTrigger from 'components/AnalyticViewTrigger';
import Card from 'components/Card';
import CardPush from 'components/CardPush';
import {EmptyStateFeed} from 'components/EmptyStateFeed';
import EvolutionStats from 'components/EvolutionStats';
import {ModalImageFullscreen} from 'components/Markdown';
import i18n from 'conf/i18n';
import {hasFlag} from 'helpers/bitwise';
import React, {useContext, useState} from 'react';
import {Trans} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTE_STANDALONE_ENTER} from 'router/routes.const';
import {generalSelector} from 'selectors';
import {EventContext, EventType} from 'services/analytic';
import {F_OPTION_PORTAL_DISPLAY_FEED} from 'services/evolution';
import {F_PAGE_FEED} from 'services/project';
import {Post} from 'shared/front/components/Post';
import './_Styles.scss';
import {AppContext} from 'App';

const Feed = () => {
  const history = useHistory();

  const project = useSelector((state) => generalSelector.getProject(state));
  const evolutions = useSelector((state) =>
    generalSelector.getEvolutions(state)
  );

  const {addFontFamily} = useContext(AppContext);

  const [focused, setFocused] = useState(null);
  const [imageFullScreen, setImageFullScreen] = useState(null);

  if (hasFlag(F_PAGE_FEED, project.portalContentFlags) === false) {
    history.push(ROUTE_STANDALONE_ENTER);
  }
  const filteredEvolutions = evolutions.filter((e) =>
    hasFlag(F_OPTION_PORTAL_DISPLAY_FEED, e.optionsFlags)
  );

  const language = i18n.language.toUpperCase();

  return (
    <div className="s-standalone-feed">
      <div
        className={classnames('evolutions-wrapper max-width-600', {
          'fade-in-bottom': focused == null,
        })}>
        {filteredEvolutions.length !== 0 ? (
          filteredEvolutions
            .sort(
              (a, b) =>
                new Date(b.lastStepChangeAt).getTime() -
                new Date(a.lastStepChangeAt).getTime()
            )
            .map((e) => {
              const isOldVersionPost = !(e?.steps?.[0]?.blocks?.length > 0);

              if (isOldVersionPost) {
                return (
                  <CardPush
                    key={e.uid}
                    evolution={e}
                    focused={focused === e.uid}
                    setFocused={() => setFocused(e.uid)}
                    onClose={() => setFocused(null)}
                    showTimestamp
                  />
                );
              } else {
                return (
                  <>
                    <Post
                      post={e}
                      theme={project.changelogStyle}
                      language={language}
                      onLaunchPoke={({pokeId, defaultUrl}) => {
                        // create url by adding jimo_poke query param
                        const url = new URL(defaultUrl);
                        url.searchParams.set('jimo_poke', pokeId);

                        window.open(url, '_blank');
                      }}
                      onUrlClick={(url) => {
                        window.open(url, '_blank');
                      }}
                      onImageClick={(src) => {
                        setImageFullScreen({src, alt: 'image'});
                      }}
                      PostBlockSocialsContent={() => (
                        <EvolutionStats evolution={e} />
                      )}
                      AnalyticViewTrigger={() => {
                        return (
                          <AnalyticViewTrigger
                            evolution={e}
                            context={EventContext.PORTAL_STANDALONE}
                            type={EventType.EVOLUTION_SEEN}
                          />
                        );
                      }}
                      addFontFamily={addFontFamily}
                    />
                    <ModalImageFullscreen
                      isOpen={imageFullScreen != null}
                      onRequestClose={() => setImageFullScreen(null)}>
                      {imageFullScreen != null && (
                        <img
                          src={imageFullScreen.src}
                          alt={imageFullScreen.alt}
                        />
                      )}
                    </ModalImageFullscreen>
                  </>
                );
              }
            })
        ) : (
          <Card className="feed-empty-state">
            <div className="title">
              <Trans
                i18nKey="others.widgetAnnouncementsHeader"
                values={{projectName: project.name}}
              />
            </div>
            <p>
              <Trans
                i18nKey="others.widgetAnnouncementsHeaderEmptyState"
                values={{projectName: project.name}}
              />
            </p>
            <EmptyStateFeed />
          </Card>
        )}
      </div>
    </div>
  );
};

export default Feed;
