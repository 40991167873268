/* eslint-disable import/first */
let dotenv = require('dotenv');
let dotenvExpand = require('dotenv-expand');
let myEnv = dotenv.config();
dotenvExpand(myEnv);
import {Environment} from 'conf/env';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import {Provider} from 'react-redux';
import {toast} from 'react-toastify';
import {PersistGate} from 'redux-persist/integration/react';
import {Swaler, SwalerLevels} from 'swaler';
import App from './App';
import './_Global.scss';
import './conf/axios';
import './conf/i18n';
import {Messenger} from './managers/messenger';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configure';

const {store, persistor} = configureStore().create();

Swaler.defaultLevel =
  Environment.NODE_ENV === 'development'
    ? SwalerLevels.TRACE
    : SwalerLevels.INFO;

Messenger.initReduxStore(store);
toast.configure({
  className: 'jimo-stormwind-toast-container',
  closeButton: false,
  closeOnClick: false,
  hideProgressBar: true,
});
ReactModal.setAppElement('#root');
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
